import React, { useState } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import axios from 'axios';

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  max-width: 300px; /* Set max-width for larger screens */
  min-width: 300px; /* Set min-width for smaller screens */
`;

const Title = styled.h1`
  text-align: center;
  margin: 20px;
  color: #042616;
  font-size: 24px; /* Tamanho da fonte igual ao de Lancamentos */
`;

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza os componentes do formulário */
  background-color: #042616;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #075933;
  width: 100%;
  max-width: 300px; /* Set max-width for larger screens */
  min-width: 300px; /* Set min-width for smaller screens */
`;

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px; /* Diminuir o espaçamento */
  margin-bottom: 8px; /* Diminuir o espaçamento */
`;

const InputArea2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Alinha os itens à esquerda */
  width: 100%; /* Garante que o contêiner ocupe a largura total */
  margin-top: 8px; /* Diminuir o espaçamento */
  margin-bottom: 8px; /* Diminuir o espaçamento */
`;

const Label = styled.label`
  padding: 0px 10px;
  color: #D9D9D9; /* Text color */
  font-size: 14px; /* Diminuir o tamanho da fonte */
`;

const Label2 = styled.label`
  padding: 0px 0px;
  color: #D9D9D9; /* Text color */
  font-size: 14px; /* Diminuir o tamanho da fonte */
`;

const Input = styled.input`
  width: 280px; /* Increased width */
  padding: 0px 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 40px;
  margin: 10px;
  color: ${({ disabled }) => (disabled ? '#A0A0A0' : '#042616')}; /* cor do texto do input */
  background-color: #D9D9D9; /* Input background color */
  font-size: 18px; /* Increased font size */
  &:focus {
    outline: 2px solid #0DA65F; /* Cor de foco */
    background-color: #D9D9D9; /* Cor de fundo ao focar */
  }
`;

const Select = styled.select`
  width: 300px; /* Increased width */
  padding: 0px 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 40px;
  margin: 10px;
  color: #042616; /*cor do texto do input */
  background-color: #D9D9D9; /* Input background color */
  font-size: 18px; /* Increased font size */
  &:focus {
    outline: 2px solid #0DA65F; /* Cor de foco */
    background-color: #D9D9D9; /* Cor de fundo ao focar */
  }
`;

const Button = styled.button`
  padding: 12px 24px; /* Increased padding */
  width: 240px;
  height: 52px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: #0DA65F; /* Button color */
  color: #D9D9D9; /* Text color */
  font-size: 24px; /* Increased font size */
  margin: 20px 40px;

  &:hover {
    background-color: #11D97C; /* Hover color */
  }

  &:disabled {
    background-color: #bbb;
    cursor: not-allowed;
  }
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0; /* Ajusta a margem */
  color: #D9D9D9; /* Text color */
  width: 100%; /* Garante que o contêiner ocupe a largura total */
  justify-content: flex-start; /* Alinha os itens à esquerda */
`;

const RadioLabel = styled.label`
  margin-left: 5px;
  margin-right: 20px; /* Add margin to create space between radio buttons */
  color: #D9D9D9; /* Text color */
  font-size: 14px;
`;

const RadioInput = styled.input.attrs({ type: 'radio' })`
  margin-right: 5px;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #bbb;
  border-radius: 3px; /* Tornar o checkbox quadrado */
  background-color: #D9D9D9;
  cursor: pointer;

  &:checked {
    background-color: #0DA65F; /* Cor de fundo ao ser selecionado */
    border-color: #042616;
  }

  &:focus {
    outline: 2px solid #0DA65F; /* Cor de foco */
  }
`;

const StyledToastContainer = styled(ToastContainer)`
  top: 80px;
`;

const validateCpf = (cpf) => {
  cpf = cpf.replace(/\D/g, '');

  if (cpf.length !== 11) return false;

  let sum = 0;
  let remainder;

  if (cpf === '00000000000') return false;

  for (let i = 1; i <= 9; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cpf.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cpf.substring(10, 11))) return false;

  return true;
};

const formatCpf = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
};

const formatBirthday = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})$/, '$1');
};

const validateBirthday = (date) => {
  const [day, month, year] = date.split('/').map(Number);
  const dateObj = new Date(year, month - 1, day);

  if (dateObj.getFullYear() !== year || dateObj.getMonth() + 1 !== month || dateObj.getDate() !== day) {
    return false;
  }

  return true;
};

const SubscriptionPage = () => {
  const [cpf, setCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [birthday, setBirthday] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [street, setStreet] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [plan, setPlan] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!plan) {
      toast.warn('Selecione um plano de assinatura');
      return;
    }

    if (!validateCpf(cpf)) {
      toast.warn('Informe um CPF válido');
      return;
    }

    if (!validateBirthday(birthday)) {
      toast.warn('Informe uma data de nascimento válida');
      return;
    }

    if (phone.length < 11) {
      toast.warn('Informe seu número de celular');
      return;
    }

    if (!streetNumber) {
      toast.warn('Informe o número do endereço');
      return;
    }

    if (streetNumber.length > 30) {
      toast.warn('Número do endereço deve ter no máximo 30 caracteres');
      return;
    }

    if (cardNumber.length !== 19) { // 16 digits + 3 spaces
      toast.warn('Informe um número de cartão válido');
      return;
    }

    if (!expiryDate.match(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/)) {
      toast.warn('Informe uma data de validade válida');
      return;
    }

    if (cvv.length !== 3) {
      toast.warn('Informe um CVV válido');
      return;
    }

    if (cardHolderName.length < 3 || /[^a-zA-Z\s]/.test(cardHolderName)) {
      toast.warn('Informe um nome no cartão válido');
      return;
    }

    setIsSubmitting(true);

    const subscriptionData = {
      id_subscriptions_user: localStorage.getItem('userId'), // Assumindo que o ID do usuário está no localStorage
      plan_subscriptions: plan,
      payment_method_subscriptions: 'creditCard',
      amount_subscriptions: plan === 'monthly' ? 990 : plan === 'quarterly' ? 2490 : plan === 'semiannual' ? 3990 : 5990,
      cardNumber: cardNumber.replace(/\s/g, ''),
      expiryDate,
      cvv,
      cardHolderName,
      cpf: cpf.replace(/\D/g, ''),
      phone: phone.replace(/\D/g, ''),
      birthday,
      state,
      city,
      neighborhood,
      street,
      streetNumber,
      zipcode,
      email: localStorage.getItem('email'), // Assumindo que o email do usuário está no localStorage
    };

    try {
      const response = await api.post('/subscriptions', subscriptionData);
      toast.success('Assinatura realizada com sucesso!');

      // Limpar os campos do formulário
      setCpf('');
      setPhone('');
      setBirthday('');
      setState('');
      setCity('');
      setNeighborhood('');
      setStreet('');
      setStreetNumber('');
      setZipcode('');
      setPlan('');
      setCardNumber('');
      setExpiryDate('');
      setCvv('');
      setCardHolderName('');

      // Redirecionar para a tela de Assinaturas após 3 segundos
      setTimeout(() => {
        navigate('/assinaturas');
      }, 3000);

    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Erro ao realizar assinatura';
      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCpfChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');
    if (value.length > 11) value = value.slice(0, 11);
    setCpf(formatCpf(value));
  };

  const handleBirthdayChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');
    if (value.length > 8) value = value.slice(0, 8);
    setBirthday(formatBirthday(value));
  };

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');
    if (value.length > 11) value = value.slice(0, 11);
    value = value.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1)$2-$3');
    setPhone(value);
  };

  const handleZipcodeChange = async (e) => {
    let value = e.target.value.replace(/\D/g, '');
    if (value.length > 8) value = value.slice(0, 8);
    setZipcode(value);
    setState('');
    setCity('');
    setNeighborhood('');
    setStreet('');
    if (value.length === 8) {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${value}/json/`);
        if (response.data.erro) {
          toast.warn('CEP não encontrado');
        } else {
          setState(response.data.uf);
          setCity(response.data.localidade);
          setNeighborhood(response.data.bairro);
          setStreet(response.data.logradouro);
        }
      } catch (error) {
        toast.error('Erro ao buscar o CEP');
      }
    }
  };

  const handleCardNumberChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');
    if (value.length > 16) value = value.slice(0, 16);
    value = value.replace(/(\d{4})(\d)/, '$1 $2')
                 .replace(/(\d{4})(\d)/, '$1 $2')
                 .replace(/(\d{4})(\d)/, '$1 $2');
    setCardNumber(value);
  };

  const handleCvvChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');
    if (value.length > 3) value = value.slice(0, 3);
    setCvv(value);
  };

  const handleExpiryDateChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');

    if (value.length > 4) value = value.slice(0, 4);

    value = value.replace(/(\d{2})(\d{2})/, '$1/$2');
    setExpiryDate(value);
  };

  return (
    <Container>
      <Title>Fazer assinatura</Title>
      <FormStyled onSubmit={handleSubmit}>
        <InputArea>
          <Label>Plano</Label>
          <Select value={plan} onChange={(e) => setPlan(e.target.value)}>
            <option value="">Selecione um plano</option>
            <option value="monthly">Mensal - R$9,90</option>
            <option value="quarterly">Trimestral - R$24,90</option>
            <option value="semiannual">Semestral - R$39,90</option>
            <option value="annual">Anual - R$59,90</option>
          </Select>
        </InputArea>
        <InputArea>
          <Label>CPF</Label>
          <Input type="text" value={cpf} onChange={handleCpfChange} placeholder="999.999.999-99" />
        </InputArea>
        <InputArea>
          <Label>Celular</Label>
          <Input type="text" value={phone} onChange={handlePhoneChange} placeholder="(99)99999-9999" />
        </InputArea>
        <InputArea>
          <Label>Data de nascimento</Label>
          <Input type="text" value={birthday} onChange={handleBirthdayChange} placeholder="DD/MM/AAAA" />
        </InputArea>
        <InputArea>
          <Label>CEP</Label>
          <Input type="text" value={zipcode} onChange={handleZipcodeChange} placeholder="99999-999" />
        </InputArea>
        <InputArea>
          <Label>Estado</Label>
          <Input type="text" value={state} disabled />
        </InputArea>
        <InputArea>
          <Label>Cidade</Label>
          <Input type="text" value={city} disabled />
        </InputArea>
        <InputArea>
          <Label>Bairro</Label>
          <Input type="text" value={neighborhood} disabled />
        </InputArea>
        <InputArea>
          <Label>Endereço</Label>
          <Input type="text" value={street} disabled />
        </InputArea>
        <InputArea>
          <Label>Número</Label>
          <Input type="text" value={streetNumber} onChange={(e) => setStreetNumber(e.target.value)} />
        </InputArea>

        <InputArea2>
          <Label2>Pagamento via:</Label2>
          <RadioContainer>
            <RadioInput
              type="radio"
              name="paymentMethod"
              value="creditCard"
              checked={true}
              readOnly
            />
            <RadioLabel>Cartão crédito (à vista)</RadioLabel>
          </RadioContainer>
        </InputArea2>

        <InputArea>
          <Label>Número do cartão</Label>
          <Input type="text" value={cardNumber} onChange={handleCardNumberChange} placeholder="XXXX XXXX XXXX XXXX" />
        </InputArea>
        <InputArea>
          <Label>Data de validade</Label>
          <Input type="text" value={expiryDate} onChange={handleExpiryDateChange} placeholder="MM/AA" />
        </InputArea>
        <InputArea>
          <Label>CVV</Label>
          <Input type="text" value={cvv} onChange={handleCvvChange} placeholder="CVV" />
        </InputArea>
        <InputArea>
          <Label>Nome no cartão</Label>
          <Input type="text" value={cardHolderName} onChange={(e) => setCardHolderName(e.target.value)} />
        </InputArea>

        <Button type="submit" disabled={isSubmitting}>Assinar</Button>
      </FormStyled>
      <StyledToastContainer autoClose={3000} position="top-left" />
    </Container>
  );
};

export default SubscriptionPage;

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FooterContainer = styled.footer`
  background-color: #042616;
  color: #D9D9D9;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin-top: 60px;
  min-width: 360px; /* Set min-width for smaller screens */
`;

const FooterText = styled.p`
  margin: 0;
  font-size: 12px;
`;

const FooterLink = styled(Link)`
  color: #D9D9D9;
  text-decoration: none;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }
`;

const Footer = ({ email }) => {
  return (
    <FooterContainer>
      {email ? (
        <FooterText>Usuário conectado: {email}</FooterText>
      ) : (
        <FooterLink to="/auth/login">Faça o login</FooterLink>
      )}
      <FooterLink to="/fale-conosco">Dúvidas? Fale Conosco</FooterLink>
    </FooterContainer>
  );
};

export default Footer;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GridAssinaturas from './GridAssinaturas';

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  max-width: 1000px; /* Set max-width for larger screens */
`;

const Title = styled.h1`
  text-align: center;
  margin: 20px;
  color: #042616;
  font-size: 24px; /* Tamanho da fonte igual ao de Lancamentos */
`;

const Message = styled.p`
  text-align: center;
  color: #042616;
  font-size: 18px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  padding: 12px 24px; /* Increased padding */
  width: 240px;
  height: 52px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: #0DA65F; /* Button color */
  color: #D9D9D9; /* Text color */
  font-size: 24px; /* Increased font size */
  margin: 20px 40px;

  &:hover {
    background-color: #11D97C; /* Hover color */
  }
`;

const SubscriptionPage = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [expirationMessage, setExpirationMessage] = useState('');
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const response = await api.get(`/subscriptions?userId=${userId}`);
        setSubscriptions(response.data);
      } catch (error) {
        console.error('Erro ao buscar assinaturas:', error);
        toast.error('Erro ao buscar assinaturas');
      }
    };

    const fetchExpirationDate = async () => {
      try {
        const response = await api.get(`/subscriptions/expiration-date?userId=${userId}`);
        const expirationDate = new Date(response.data.expiration_date_subscriptions);
        const today = new Date();
        if (expirationDate < today) {
          setExpirationMessage(`Sua assinatura venceu ${expirationDate.toLocaleDateString('pt-BR')}`);
        } else {
          setExpirationMessage(`Sua assinatura vencerá ${expirationDate.toLocaleDateString('pt-BR')}`);
        }
      } catch (error) {
        console.error('Erro ao buscar data de expiração da assinatura:', error);
        toast.error('Erro ao buscar data de expiração da assinatura');
      }
    };

    fetchSubscriptions();
    fetchExpirationDate();
  }, [userId]);

  return (
    <Container>
      <Title>Assinaturas</Title>
      <Message>{expirationMessage}</Message>
      <GridAssinaturas expenses={subscriptions} setExpenses={setSubscriptions} />
      <Button onClick={() => navigate('/fazer-assinatura')}>Fazer assinatura</Button>
    </Container>
  );
};

export default SubscriptionPage;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../api';

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  max-width: 300px; /* Set max-width for larger screens */
  min-width: 300px; /* Set min-width for smaller screens */
`;

const Title = styled.h1`
  text-align: center;
  margin: 20px;
  color: #042616;
  font-size: 24px; /* Tamanho da fonte igual ao de Lancamentos */
`;

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza os componentes do formulário */
  background-color: #042616;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #075933;
  width: 100%;
  max-width: 300px; /* Set max-width for larger screens */
  min-width: 300px; /* Set min-width for smaller screens */
`;

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px; /* Diminuir o espaçamento */
  margin-bottom: 8px; /* Diminuir o espaçamento */
`;

const Label = styled.label`
  padding: 0px 10px;
  color: #D9D9D9; /* Text color */
  font-size: 14px; /* Diminuir o tamanho da fonte */
`;

const Input = styled.input`
  width: 280px; /* Increased width */
  padding: 0px 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 40px;
  margin: 10px;
  color: ${({ disabled }) => (disabled ? '#A0A0A0' : '#042616')}; /* cor do texto do input */
  background-color: #D9D9D9; /* Input background color */
  font-size: 18px; /* Increased font size */
  &:focus {
    outline: 2px solid #0DA65F; /* Cor de foco */
    background-color: #D9D9D9; /* Cor de fundo ao focar */
  }
`;

const Button = styled.button`
  padding: 12px 24px; /* Increased padding */
  width: 240px;
  height: 52px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: #0DA65F; /* Button color */
  color: #D9D9D9; /* Text color */
  font-size: 24px; /* Increased font size */
  margin: 20px 40px;

  &:hover {
    background-color: #11D97C; /* Hover color */
  }

  &:disabled {
    background-color: #bbb;
    cursor: not-allowed;
  }
`;

const StyledToastContainer = styled(ToastContainer)`
  top: 80px;
`;

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.email) {
      setEmail(location.state.email);
    }
  }, [location.state]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      toast.warn('Informe um email válido');
      return;
    }

    setIsSubmitting(true);

    try {
      await api.post('/auth/forgotpassword', { email });
      toast.success('Nova senha enviada para o seu email.');
      setTimeout(() => {
        navigate('/auth/login');
      }, 4000);
    } catch (error) {
      const errorMessage = error.response?.data || 'Erro ao enviar nova senha';
      toast.error(errorMessage);
      console.error('Erro ao enviar nova senha', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      <Title>Esqueci minha senha</Title>
      <FormStyled onSubmit={handleSubmit}>
        <InputArea>
          <Label>Email</Label>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </InputArea>
        <Button type="submit" disabled={isSubmitting}>Redefinir Senha</Button>
      </FormStyled>
      <StyledToastContainer autoClose={3000} position="top-left" />
    </Container>
  );
};

export default ForgotPassword;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FormReceitas from './FormReceitas.js';
import 'react-toastify/dist/ReactToastify.css';
import api from '../api.js';

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  max-width: 300px; /* Set max-width for larger screens */
  min-width: 300px; /* Set min-width for smaller screens */
`;

const Title = styled.h1`
  text-align: center;
  margin: 20px;
  color: #042616;
  font-size: 24px; /* Tamanho da fonte igual ao de Lancamentos */
`;

const Receitas = () => {
  const [onEdit, setOnEdit] = useState(null);
  const token = localStorage.getItem('token');

  const getRevenues = async () => {
    try {
      await api.get('/revenue');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token) {
      getRevenues();
    }
  }, [token]);

  return (
    <Container>
      <Title>Lançar receitas</Title>
      <FormReceitas onEdit={onEdit} setOnEdit={setOnEdit} getRevenues={getRevenues} />
    </Container>
  );
};

export default Receitas;

import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import api from '../api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Link } from 'react-router-dom';

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  max-width: 300px; /* Set max-width for larger screens */
  min-width: 300px; /* Set min-width for smaller screens */
`;

const Title = styled.h1`
  text-align: center;
  margin: 20px;
  color: #042616;
  font-size: 24px; /* Tamanho da fonte igual ao de Lancamentos */
`;

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza os componentes do formulário */
  background-color: #042616;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #075933;
  width: 100%;
  max-width: 300px; /* Set max-width for larger screens */
  min-width: 300px; /* Set min-width for smaller screens */
`;

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px; /* Diminuir o espaçamento */
  margin-bottom: 8px; /* Diminuir o espaçamento */
`;

const Label = styled.label`
  padding: 0px 10px;
  color: #D9D9D9; /* Text color */
  font-size: 14px; /* Diminuir o tamanho da fonte */
`;

const Input = styled.input`
  width: 280px; /* Increased width */
  padding: 0px 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 40px;
  margin: 10px;
  color: #042616; /*cor do texto do input */
  background-color: #D9D9D9; /* Input background color */
  font-size: 18px; /* Increased font size */
  &:focus {
    outline: 2px solid #0DA65F; /* Cor de foco */
    background-color: #D9D9D9; /* Cor de fundo ao focar */
  }
`;

const Button = styled.button`
  padding: 12px 24px; /* Increased padding */
  width: 240px;
  height: 52px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: #0DA65F; /* Button color */
  color: #D9D9D9; /* Text color */
  font-size: 24px; /* Increased font size */
  margin: 20px 40px;

  &:hover {
    background-color: #11D97C; /* Hover color */
  }

  &:disabled {
    background-color: #bbb;
    cursor: not-allowed;
  }
`;

const LinksContainer = styled.div`
  width: 100%;
  max-width: 330px; /* Ajustar para combinar com a largura do formulário */
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;
`;

const StyledLink = styled(Link)`
  color: #042616;
  text-decoration: none;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledToastContainer = styled(ToastContainer)`
  top: 80px;
`;

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [phone, setPhone] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const fullNameRef = useRef(null);
  const phoneRef = useRef(null);
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');
    if (value.length > 11) value = value.slice(0, 11);
    value = value.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1)$2-$3');
    setPhone(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      toast.warn('Informe um email válido');
      return;
    }

    if (password.length < 8) {
      toast.warn('Informe uma senha com pelo menos 8 caracteres');
      return;
    }

    if (password.length > 40) {
      toast.warn('Informe uma senha com no máximo 40 caracteres');
      return;
    }

    if (password !== confirmPassword) {
      toast.warn('As senhas informadas estão diferentes');
      return;
    }

    if (fullName.length < 5) {
      toast.warn('Informe seu nome completo');
      return;
    }

    if (fullName.length > 100) {
      toast.warn('Informe seu nome completo com no máximo 100 caracteres');
      return;
    }

    const formatText = (text) => {
      return text
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    };

    const formattedFullName = formatText(fullName);

    if (phone.length < 11) {
      toast.warn('Informe seu número de celular');
      return;
    }

    setIsSubmitting(true);

    try {
      await api.post('/auth/register', {
        email,
        password,
        nome_user: formattedFullName,
        fone_user: phone,
      });

      toast.success('Insira o código enviado para seu email. Redirecionando para a página de verificação.');
      setTimeout(() => {
        navigate('/auth/verifyemail', { state: { email } });
      }, 3000);
    } catch (error) {
      const errorMessage = error.response?.data || 'Erro ao registrar usuário';
      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      <Title>Registrar</Title>
      <FormStyled onSubmit={handleSubmit}>
        <InputArea>
          <Label>Email</Label>
          <Input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            ref={emailRef}
            disabled={isSubmitting}
          />
        </InputArea>
        <InputArea>
          <Label>Senha</Label>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            ref={passwordRef}
            disabled={isSubmitting}
          />
        </InputArea>
        <InputArea>
          <Label>Confirmação de senha</Label>
          <Input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            ref={confirmPasswordRef}
            disabled={isSubmitting}
          />
        </InputArea>
        <InputArea>
          <Label>Nome completo</Label>
          <Input
            type="text"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            ref={fullNameRef}
            disabled={isSubmitting}
          />
        </InputArea>
        <InputArea>
          <Label>Celular</Label>
          <Input
            type="text"
            value={phone}
            onChange={handlePhoneChange}
            ref={phoneRef}
            placeholder="(99)99999-9999"
            disabled={isSubmitting}
          />
        </InputArea>
        <Button type="submit" disabled={isSubmitting}>Registrar</Button>
      </FormStyled>
      <LinksContainer>
        <StyledLink to="/auth/verifyemail">Verificar email</StyledLink>
      </LinksContainer>
      <StyledToastContainer autoClose={3000} position="top-left" />
    </Container>
  );
};

export default Register;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import api from '../api';
import GridDespesas from './GridDespesas.js';
import GridReceitas from './GridReceitas';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import { FaSearch, FaFileExcel } from 'react-icons/fa';
import * as XLSX from 'xlsx';

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px 10px 10px 10px;
  max-width: 1000px; /* Set max-width for larger screens */
`;

const Title = styled.h1`
  text-align: center;
  margin: 20px;
  color: #042616;
  font-size: 24px; /* Tamanho da fonte igual ao de Lancamentos */
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #042616;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #075933;
  gap: 20px;
  width: 100%;
`;

const FilterIcon = styled(FaSearch)`
  color: #D9D9D9;
  font-size: 20px;
  align-items: center;
  padding: 18px;
`;

const FilterItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Centralizar conteúdo */
`;

const Label = styled.label`
  padding: 0px 10px;
  color: #D9D9D9; /* Text color */
  font-size: 14px; /* Diminuir o tamanho da fonte */
`;

const Select = styled.select`
  width: 300px; /* Increased width */
  padding: 0px 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 42px;
  margin: 10px;
  color: #042616; /*cor do texto do input */
  background-color: #D9D9D9; /* Input background color */
  font-size: 18px; /* Increased font size */
  &:focus {
    outline: 2px solid #0DA65F; /* Cor de foco */
    background-color: #D9D9D9; /* Cor de fundo ao focar */
  }
`;

const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const InfoContainer1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start; 
  margin-left: 10px;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const InfoContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
`;

const InfoLabel1 = styled.div`
  width: 100%;
  padding: 0px 10px;
  font-size: 18px;
  color: #042616;
`;

const InfoLabel2 = styled.div`
  width: 100%;
  padding: 0px 10px;
  font-size: 15px;
  color: #042616;
`;

const ChartContainer = styled.div`
  width: 100%;
  max-width: 280px;
  min-height: 280px;
  max-height: 280px;
  margin: 0px auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
  width: 100%;
`;

const ExportButton = styled.button`
  display: flex;
  align-items: center;
  background-color: #0DA65F;
  color: #D9D9D9;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #11D97C;
  }
`;

const StyledToastContainer = styled(ToastContainer)`
  top: 80px;
`;

const ExportIcon = styled(FaFileExcel)`
  margin-right: 10px;
`;

const VisaoGeral = () => {
  const [expenses, setExpenses] = useState([]);
  const [revenues, setRevenues] = useState([]);
  const [formPayments, setFormPayments] = useState([]);
  const [tipoDespesas, setTipoDespesas] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1); // Set to current month
  const [formPayment, setFormPayment] = useState('');
  const [tipoDespesa, setTipoDespesa] = useState('');
  const [parcelado, setParcelado] = useState('');

  const getRevenues = async (filters = {}) => {
    try {
      const res = await api.get('/revenue', {
        params: filters,
      });
      setRevenues(res.data);
    } catch (error) {
      toast.error('Erro ao buscar receitas');
      console.error(error);
    }
  };

  const getExpenses = async (filters = {}) => {
    try {
      const res = await api.get('/expense', {
        params: filters,
      });
      setExpenses(res.data);
    } catch (error) {
      toast.error('Erro ao buscar despesas');
      console.error(error);
    }
  };

  const getFormPaymentsDropDown = async () => {
    try {
      const res = await api.get('/forma-pagamento/dropdown');
      setFormPayments(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTipoDespesasDropDown = async () => {
    try {
      const res = await api.get('/tipo-despesa/dropdown');
      setTipoDespesas(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilterChange = () => {
    const filters = {
      year,
      month: month || undefined,
      formPayment: formPayment || undefined,
      tipoDespesa: tipoDespesa || undefined,
      parcelado: parcelado || undefined,
    };
    getRevenues(filters);
    getExpenses(filters);
  };

  useEffect(() => {
    getExpenses({ year, month });
    getRevenues({ year, month });
    getFormPaymentsDropDown();
    getTipoDespesasDropDown();
  }, []);

  useEffect(() => {
    handleFilterChange();
  }, [year, month, formPayment, tipoDespesa, parcelado]);

  const totalRevenues = revenues.length;
  const totalValorRevenues = revenues.reduce((acc, curr) => acc + parseFloat(curr.valor_revenue), 0).toFixed(2);

  const formattedTotalValorRevenues = parseFloat(totalValorRevenues).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  
  const totalDespesas = expenses.length;
  const valorTotalDespesas = expenses.reduce((acc, curr) => acc + parseFloat(curr.valor_expense), 0).toFixed(2);

  const formattedValorTotalDespesas = parseFloat(valorTotalDespesas).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const valorTotalPorTipo = expenses.reduce((acc, curr) => {
    const { tipo_expense, valor_expense } = curr;
    if (!acc[tipo_expense]) {
      acc[tipo_expense] = 0;
    }
    acc[tipo_expense] += parseFloat(valor_expense);
    return acc;
  }, {});

  const valorTotalPorForma = expenses.reduce((acc, curr) => {
    const { formapgto_expense, valor_expense } = curr;
    if (!acc[formapgto_expense]) {
      acc[formapgto_expense] = 0;
    }
    acc[formapgto_expense] += parseFloat(valor_expense);
    return acc;
  }, {});

  const chartDataTipo = {
    labels: Object.keys(valorTotalPorTipo),
    datasets: [
      {
        data: Object.values(valorTotalPorTipo),
        backgroundColor: [
          '#075933',
          '#E65100',
          '#4A0072',
          '#5D4037',
          '#DAB3E5',
          '#8B5A2B',
          '#A3D9A5',
          '#FFB74D',
          '#0DA65F',
          '#F57C00',
          '#6A1B9A',
          '#D2691E',
          '#2C003E',
          '#3E2723',
          '#042616',
          '#BF360C',
          '#11D97C',
          '#FF9800',
          '#A64CA6',
          '#A0522D',
        ],
        hoverBackgroundColor: [
          '#075933',
          '#E65100',
          '#4A0072',
          '#5D4037',
          '#DAB3E5',
          '#8B5A2B',
          '#A3D9A5',
          '#FFB74D',
          '#0DA65F',
          '#F57C00',
          '#6A1B9A',
          '#D2691E',
          '#2C003E',
          '#3E2723',
          '#042616',
          '#BF360C',
          '#11D97C',
          '#FF9800',
          '#A64CA6',
          '#A0522D',
        ],
      },
    ],
  };

  const chartDataForma = {
    labels: Object.keys(valorTotalPorForma),
    datasets: [
      {
        data: Object.values(valorTotalPorForma),
        backgroundColor: [
          '#075933',
          '#E65100',
          '#4A0072',
          '#5D4037',
          '#DAB3E5',
          '#8B5A2B',
          '#A3D9A5',
          '#FFB74D',
          '#0DA65F',
          '#F57C00',
          '#6A1B9A',
          '#D2691E',
          '#2C003E',
          '#3E2723',
          '#042616',
          '#BF360C',
          '#11D97C',
          '#FF9800',
          '#A64CA6',
          '#A0522D',
        ],
        hoverBackgroundColor: [
          '#075933',
          '#E65100',
          '#4A0072',
          '#5D4037',
          '#DAB3E5',
          '#8B5A2B',
          '#A3D9A5',
          '#FFB74D',
          '#0DA65F',
          '#F57C00',
          '#6A1B9A',
          '#D2691E',
          '#2C003E',
          '#3E2723',
          '#042616',
          '#BF360C',
          '#11D97C',
          '#FF9800',
          '#A64CA6',
          '#A0522D',
        ],
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const handleExportDespesasExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      expenses.map((expense, index) => ({
        '#': index + 1,
        'Data do lançamento': formatDate(expense.date_expense),
        'Valor R$': formatCurrency(expense.valor_expense),
        'Forma de pagamento': expense.formapgto_expense,
        'Parcelado': expense.parcelado_expense ? 'Sim' : 'Não',
        'Tipo de despesa': expense.tipo_expense,
        Descrição: expense.descricao_expense,
      }))
    );

    worksheet['!autofilter'] = { ref: 'B1:G1' };

    worksheet['!cols'] = [
      { wpx: 30 },
      { wpx: 122 },
      { wpx: 122 },
      { wpx: 144 },
      { wpx: 77 },
      { wpx: 144 },
      { wpx: 222 },
    ];
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Despesas');
    XLSX.writeFile(workbook, 'despesas.xlsx');
  };

  const handleExportReceitasExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      revenues.map((revenue, index) => ({
        '#': index + 1,
        'Data da receita': formatDate(revenue.date_revenue),
        'Valor R$': formatCurrency(revenue.valor_revenue),
        Descrição: revenue.descricao_revenue,
      }))
    );

    worksheet['!autofilter'] = { ref: 'B1:C1' };

    worksheet['!cols'] = [
      { wpx: 30 },
      { wpx: 122 },
      { wpx: 122 },
      { wpx: 222 },
    ];
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Receitas');
    XLSX.writeFile(workbook, 'receitas.xlsx');
  };

  const formatDate = (dateTime) => {
    const date = new Date(dateTime);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatCurrency = (value) => {
    return parseFloat(value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  return (
    <Container>
      <Title>Visão Geral</Title>
      <FilterContainer>
        <FilterIcon />
        <FilterItem>
          <Label>Ano</Label>
          <Select value={year} onChange={(e) => setYear(e.target.value)}>
            {Array.from({ length: 7 }, (_, i) => new Date().getFullYear() + i).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Select>
        </FilterItem>
        <FilterItem>
          <Label>Mês</Label>
          <Select value={month} onChange={(e) => setMonth(e.target.value)}>
            <option value="">Todos</option>
            {[
              'Janeiro',
              'Fevereiro',
              'Março',
              'Abril',
              'Maio',
              'Junho',
              'Julho',
              'Agosto',
              'Setembro',
              'Outubro',
              'Novembro',
              'Dezembro',
            ].map((month, index) => (
              <option key={index} value={index + 1}>
                {month}
              </option>
            ))}
          </Select>
        </FilterItem>
        <FilterItem>
          <Label>Forma de pagamento</Label>
          <Select value={formPayment} onChange={(e) => setFormPayment(e.target.value)}>
            <option value="">Todos</option>
            {formPayments.map((payment) => (
              <option key={payment.id_form_payment} value={payment.nome_form_payment}>
                {payment.nome_form_payment}
              </option>
            ))}
          </Select>
        </FilterItem>
        <FilterItem>
          <Label>Tipo de despesa</Label>
          <Select value={tipoDespesa} onChange={(e) => setTipoDespesa(e.target.value)}>
            <option value="">Todos</option>
            {tipoDespesas.map((tipo) => (
              <option key={tipo.id_type_expense} value={tipo.nome_type_expense}>
                {tipo.nome_type_expense}
              </option>
            ))}
          </Select>
        </FilterItem>
        <FilterItem>
          <Label>Parcelado</Label>
          <Select value={parcelado} onChange={(e) => setParcelado(e.target.value)}>
            <option value="">Todos</option>
            <option value="1">Sim</option>
            <option value="0">Não</option>
          </Select>
        </FilterItem>
      </FilterContainer>

      <InfoContainer>
        <InfoLabel1>Quantidade de receitas: {totalRevenues}</InfoLabel1>
        <InfoLabel1>Valor total de receitas: {formattedTotalValorRevenues}</InfoLabel1>
      </InfoContainer>
      <InfoContainer>
        <InfoLabel1>Quantidade de despesas: {totalDespesas}</InfoLabel1>
        <InfoLabel1>Valor total de despesas: {formattedValorTotalDespesas}</InfoLabel1>
      </InfoContainer>

      {totalDespesas > 0 && (
        <>
          <InfoContainer1>
            <InfoContainer2>
              <InfoLabel1>Tipo de despesa:</InfoLabel1>
              {Object.keys(valorTotalPorTipo)
                .sort((a, b) => a.localeCompare(b))
                .map((tipo) => (
                  <InfoLabel2 key={tipo}>
                    {tipo}:{' '}
                    {valorTotalPorTipo[tipo].toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </InfoLabel2>
                ))}
            </InfoContainer2>
            <InfoContainer2>
              <ChartContainer>
                <Doughnut data={chartDataTipo} options={chartOptions} />
              </ChartContainer>
            </InfoContainer2>
          </InfoContainer1>

          <InfoContainer1>
            <InfoContainer2>
              <InfoLabel1>Forma de pagamento:</InfoLabel1>
              {Object.keys(valorTotalPorForma)
                .sort((a, b) => a.localeCompare(b))
                .map((forma) => (
                  <InfoLabel2 key={forma}>
                    {forma}:{' '}
                    {valorTotalPorForma[forma].toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </InfoLabel2>
                ))}
            </InfoContainer2>
            <InfoContainer2>
              <ChartContainer>
                <Doughnut data={chartDataForma} options={chartOptions} />
              </ChartContainer>
            </InfoContainer2>
          </InfoContainer1>

          <ButtonContainer>
            <ExportButton onClick={handleExportDespesasExcel}>
              <ExportIcon />
              Salvar Despesas
            </ExportButton>
          </ButtonContainer>
          <GridDespesas expenses={expenses} setExpenses={setExpenses} />
          <InfoContainer />
        </>
      )}

      {totalRevenues > 0 && (
        <>
          <ButtonContainer>
            <ExportButton onClick={handleExportReceitasExcel}>
              <ExportIcon />
              Salvar Receitas
            </ExportButton>
          </ButtonContainer>
          <GridReceitas revenues={revenues} setRevenues={setRevenues} />
        </>
      )}

      <StyledToastContainer autoClose={3000} position="top-left" />
    </Container>
  );
};

export default VisaoGeral;

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { NumericFormat } from 'react-number-format';
import api from '../api';

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza os componentes do formulário */
  background-color: #042616;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #075933;
  width: 100%;
  max-width: 300px; /* Set max-width for larger screens */
  min-width: 300px; /* Set min-width for smaller screens */
`;

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px; /* Diminuir o espaçamento */
  margin-bottom: 8px; /* Diminuir o espaçamento */
`;

const InputArea2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Alinha os itens à esquerda */
  width: 100%; /* Garante que o contêiner ocupe a largura total */
  margin-top: 8px; /* Diminuir o espaçamento */
  margin-bottom: 8px; /* Diminuir o espaçamento */
`;

const Label = styled.label`
  padding: 0px 10px;
  color: #D9D9D9; /* Text color */
  font-size: 14px; /* Diminuir o tamanho da fonte */
`;

const Select = styled.select`
  width: 300px; /* Increased width */
  padding: 0px 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 42px;
  margin: 10px;
  color: #042616; /*cor do texto do input */
  background-color: #D9D9D9; /* Input background color */
  font-size: 18px; /* Increased font size */
  &:focus {
    outline: 2px solid #0DA65F; /* Cor de foco */
    background-color: #D9D9D9; /* Cor de fundo ao focar */
  }
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0; /* Ajusta a margem */
  color: #D9D9D9; /* Text color */
  width: 100%; /* Garante que o contêiner ocupe a largura total */
  justify-content: flex-start; /* Alinha os itens à esquerda */
`;

const RadioLabel = styled.label`
  margin-left: 5px;
  margin-right: 20px; /* Add margin to create space between radio buttons */
  color: #D9D9D9; /* Text color */
  font-size: 14px;
`;

const Label2 = styled.label`
  color: #D9D9D9; /* Text color */
  font-size: 14px; /* Diminuir o tamanho da fonte */
`;

const RadioInput = styled.input.attrs({ type: 'radio' })`
  margin-right: 5px;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #bbb;
  border-radius: 3px; /* Tornar o checkbox quadrado */
  background-color: #D9D9D9;
  cursor: pointer;

  &:checked {
    background-color: #0DA65F; /* Cor de fundo ao ser selecionado */
    border-color: #042616;
  }

  &:focus {
    outline: 2px solid #0DA65F; /* Cor de foco */
  }
`;

const Button = styled.button`
  padding: 12px 24px; /* Increased padding */
  width: 240px;
  height: 52px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: #0DA65F; /* Button color */
  color: #D9D9D9; /* Text color */
  font-size: 24px; /* Increased font size */
  margin: 20px 40px;

  &:hover {
    background-color: #11D97C; /* Hover color */
  }

  &:disabled {
    background-color: #bbb;
    cursor: not-allowed;
  }
`;

const NumberInput = styled(NumericFormat)`
  width: 280px; /* Increased width */
  padding: 0px 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 40px;
  margin: 10px;
  color: #042616; /*cor do texto do input */
  background-color: #D9D9D9; /* Input background color */
  font-size: 18px; /* Increased font size */
  &:focus {
    outline: 2px solid #0DA65F; /* Cor de foco */
    background-color: #D9D9D9; /* Cor de fundo ao focar */
  }
`;

const TextInput = styled.input`
  width: 280px; /* Increased width */
  padding: 0px 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 40px;
  margin: 10px;
  color: #042616; /*cor do texto do input */
  background-color: #D9D9D9; /* Input background color */
  font-size: 18px; /* Increased font size */
  &:focus {
    outline: 2px solid #0DA65F; /* Cor de foco */
    background-color: #D9D9D9; /* Cor de fundo ao focar */
  }
`;

const DateInput = styled.input`
  width: 280px;
  padding: 0px 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 40px;
  margin: 10px;
  color: #042616;
  background-color: #D9D9D9;
  font-size: 18px;
  font-family: sans-serif;
  font-weight: normal; /* Fonte normal */
  &:focus {
    outline: 2px solid #0DA65F;
    background-color: #D9D9D9;
  }
`;

const StyledToastContainer = styled(ToastContainer)`
  top: 80px;
`;

const FormDespesas = ({ onEdit, setOnEdit, formPayments, tipoDespesas }) => {
  const navigate = useNavigate();
  const formapgtoRef = useRef(null);
  const numparcelaRef = useRef(null);
  const tipoRef = useRef(null);
  const descricaoRef = useRef(null);

  const [valor, setValor] = useState('0,00');
  const [parcelado, setParcelado] = useState(false);

  // Função para obter a data local do usuário
  const getLocalDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [dataDespesa, setDataDespesa] = useState(getLocalDate());
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (onEdit) {
      setValor(onEdit.valor_expense);
      setDataDespesa(onEdit.date_expense || getLocalDate());
      if (formapgtoRef.current) formapgtoRef.current.value = onEdit.formapgto_expense;
      setParcelado(onEdit.parcelado_expense === 1);
      if (numparcelaRef.current) numparcelaRef.current.value = onEdit.numparcela_expense;
      if (tipoRef.current) tipoRef.current.value = onEdit.tipo_expense;
      if (descricaoRef.current) descricaoRef.current.value = onEdit.descricao_expense;
    } else {
      setValor('0,00');
    }
  }, [onEdit]);

  const handleParceladoChange = (value) => {
    setParcelado(value === 'Sim');
  };

  const handleFormapgtoClick = () => {
    if (formPayments.length === 0) {
      toast.warn('Nenhuma forma de pagamento cadastrada. Redirecionando para a página de cadastro.');
      setTimeout(() => {
        navigate('/forma-pagamento');
      }, 3000);
    }
  };

  const handleTipoDespesaClick = () => {
    if (tipoDespesas.length === 0) {
      toast.warn('Nenhum tipo de despesa cadastrada. Redirecionando para a página de cadastro.');
      setTimeout(() => {
        navigate('/tipo-despesa');
      }, 3000);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmitting(true);

    try {
      // Verificar a data de expiração da assinatura
      const expirationResponse = await api.get(`/subscriptions/expiration-date?userId=${localStorage.getItem('userId')}`);
      const expirationDate = new Date(expirationResponse.data.expiration_date_subscriptions);
      const today = new Date();

      // Formatando as datas no padrão UTC
      const expirationDateUTC = new Date(Date.UTC(expirationDate.getUTCFullYear(), expirationDate.getUTCMonth(), expirationDate.getUTCDate()));
      const todayUTC = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));

      // Zerando as horas, minutos, segundos e milissegundos
      expirationDateUTC.setHours(0, 0, 0, 0);
      todayUTC.setHours(0, 0, 0, 0);

      if (expirationDateUTC < todayUTC) {
        toast.warn('Renove sua assinatura para lançar novas despesas', expirationDate);
        setIsSubmitting(false);
        return;
      }
    } catch (error) {
      console.error('Erro ao verificar a data de expiração da assinatura:', error);
      toast.error('Erro ao verificar a assinatura.');
      setIsSubmitting(false);
      return;
    }

    // Validações dos campos
    if (!dataDespesa) {
      toast.warn('Informe uma data válida para a despesa');
      setIsSubmitting(false);
      return;
    }

    const valorNumerico = parseFloat(valor.replace(/\./g, '').replace(',', '.'));
    if (isNaN(valorNumerico) || valorNumerico <= 0) {
      toast.warn('Informe um valor numérico maior que zero');
      setIsSubmitting(false);
      return;
    }

    if (valorNumerico >= 1000000000) {
      toast.warn('Informe um valor menor que 1.000.000.000,00');
      setIsSubmitting(false);
      return;
    }

    if (!formapgtoRef.current?.value) {
      toast.warn('Selecione uma forma de pagamento');
      setIsSubmitting(false);
      return;
    }

    if (parcelado && !numparcelaRef.current?.value) {
      toast.warn('Selecione a quantidade de parcelas');
      setIsSubmitting(false);
      return;
    }

    if (!tipoRef.current?.value) {
      toast.warn('Selecione um tipo de despesa');
      setIsSubmitting(false);
      return;
    }

    if (!descricaoRef.current?.value) {
      toast.warn('Informe uma descrição');
      setIsSubmitting(false);
      return;
    }

    if (descricaoRef.current?.value.length > 80) {
      toast.warn('Descrição da despesa deve ter no máximo 80 caracteres');
      setIsSubmitting(false);
      return;
    }

    // Função para formatar o texto
    const formatText = (text) => {
      return text
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    };

    const formattedDescricao = formatText(descricaoRef.current.value);

    const valorTotal = valorNumerico;
    const numParcelas = parcelado ? parseInt(numparcelaRef.current.value, 10) : 1;
    const valorParcela = (valorTotal / numParcelas).toFixed(2);

    // Manter a data selecionada com hora fixa ao meio-dia
    const [year, month, day] = dataDespesa.split('-');
    const dataLocal = new Date(Date.UTC(year, month - 1, day, 12, 0, 0));

    // Transformar a data em timestamp
    const dataFormatada = dataLocal.toISOString().replace('T', ' ').split('.')[0];

    let mesCorrente = String(dataLocal.getUTCMonth() + 1).padStart(2, '0'); 
    let anoCorrente = dataLocal.getUTCFullYear(); 

    try {
      const requests = []; // To store the promises

      for (let i = 0; i < numParcelas; i++) {
        const expense = {
          valor_expense: parseFloat(valorParcela),
          formapgto_expense: formapgtoRef.current.value,
          parcelado_expense: parcelado ? 1 : 0,
          numparcela_expense: i + 1,
          tipo_expense: tipoRef.current.value,
          descricao_expense: parcelado ? `${formattedDescricao} (Parcela ${i + 1}/${numParcelas})` : formattedDescricao,
          date_expense: dataFormatada,
          ano_expense: anoCorrente,
          mes_expense: mesCorrente,
        };

        requests.push(api.post('/expense', expense));

        // Increment month and adjust year
        if (parcelado) {
          mesCorrente++;
          if (mesCorrente > 12) {
            mesCorrente = 1;
            anoCorrente++;
          }
        }
      }

      await Promise.all(requests); // Await all requests to finish

      toast.success(parcelado ? `Despesa parcelada em ${numParcelas} vezes adicionada com sucesso` : 'Despesa adicionada com sucesso');

      // Limpar os campos após o salvamento
      setOnEdit(null);
      setValor('0,00');
      setDataDespesa(getLocalDate());
      if (formapgtoRef.current) formapgtoRef.current.value = '';
      setParcelado(false);
      if (numparcelaRef.current) numparcelaRef.current.value = '';
      if (tipoRef.current) tipoRef.current.value = '';
      if (descricaoRef.current) descricaoRef.current.value = '';
    } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data === 'Limite atingido') {
        toast.warn('Limite atingido - Você pode fazer no máximo 300 lançamentos por dia');
      } else {
        console.error('Error saving expense:', error);
        toast.error('Erro ao salvar a despesa');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <InputArea>
        <Label>Data da despesa:</Label>
        <DateInput
          type="date"
          value={dataDespesa}
          onChange={(e) => setDataDespesa(e.target.value)}
        />
      </InputArea>
      <InputArea>
        <Label>Valor total R$:</Label>
        <NumberInput
          name="valor_expense"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          fixedDecimalScale={true}
          allowNegative={false}
          prefix=""
          value={valor}
          onValueChange={(values) => setValor(values.formattedValue)}
          onClick={(e) => e.target.select()} // Seleciona o conteúdo ao clicar
        />
      </InputArea>
      <InputArea>
        <Label>Forma de pagamento:</Label>
        <Select 
          name="formapgto_expense" 
          ref={formapgtoRef}
          onClick={handleFormapgtoClick} // Adiciona o evento onClick aqui
        >
          <option value="">Selecione</option>
          {formPayments.map((payment) => (
            <option key={payment.id_form_payment} value={payment.nome_form_payment}>
              {payment.nome_form_payment}
            </option>
          ))}
        </Select>
      </InputArea>
      <InputArea2>
        <Label2>Parcelado?</Label2>
        <RadioContainer>
          <RadioInput
            name="parcelado_expense"
            value="Sim"
            checked={parcelado}
            onChange={() => handleParceladoChange('Sim')}
          />
          <RadioLabel>Sim</RadioLabel>
          <RadioInput
            name="parcelado_expense"
            value="Não"
            checked={!parcelado}
            onChange={() => handleParceladoChange('Não')}
          />
          <RadioLabel>Não</RadioLabel>
        </RadioContainer>
      </InputArea2>
      {parcelado && (
        <InputArea>
          <Label>Em quantas vezes:</Label>
          <Select name="numparcela_expense" ref={numparcelaRef}>
            <option value="">Selecione</option>
            {Array.from({ length: 17 }, (_, i) => i + 2).map((num) => (
              <option key={num} value={num}>{`${num.toString().padStart(2, '0')} vezes`}</option>
            ))}
          </Select>
        </InputArea>
      )}
      <InputArea>
        <Label>Tipo de despesa:</Label>
        <Select 
          name="tipo_expense" 
          ref={tipoRef}
          onClick={handleTipoDespesaClick} // Adiciona o evento onClick aqui
        >
          <option value="">Selecione</option>
          {tipoDespesas.map((tipo) => (
            <option key={tipo.id_type_expense} value={tipo.nome_type_expense}>
              {tipo.nome_type_expense}
            </option>
          ))}
        </Select>
      </InputArea>
      <InputArea>
        <Label>Descrição da despesa:</Label>
        <TextInput name="descricao_expense" type="text" ref={descricaoRef} />
      </InputArea>
      <Button type="submit" disabled={isSubmitting}>Salvar</Button>
      <StyledToastContainer autoClose={3000} position="top-left" />
    </FormContainer>
  );
};

export default FormDespesas;

import React, { useEffect, useState } from 'react';
import GlobalStyle from './global.js';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import Login from './components/Login.js';
import Register from './components/Register.js';
import VerifyEmail from './components/VerifyEmail.js';
import Navbar from './components/Navbar';
import DadosPessoais from './components/DadosPessoais';
import FormaPagamento from './components/FormaPagamento';
import TipoDespesa from './components/TipoDespesa';
import VisaoGeral from './components/VisaoGeral';
import Despesas from './components/Despesas';
import Receitas from './components/Receitas';
import Footer from './components/Footer.js';
import ForgotPassword from './components/ForgotPassword.js';
import FaleConosco from './components/FaleConosco.js';
import ProtectedRoute from './components/ProtectedRoute';
import PublicRoute from './components/PublicRoute';
import NotFound from './components/NotFound';
import SubscriptionPage from './components/SubscriptionPage';
import FormSubscriptionPage from './components/FormSubscriptionPage';
import LandingPage from './components/LandingPage'; 

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const App = () => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [isLoggedIn, setIsLoggedIn] = useState(!!token);
  const email = localStorage.getItem('email');

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const stayLoggedIn = localStorage.getItem('stayLoggedIn');

    if (storedToken && stayLoggedIn === 'true') {
      setToken(storedToken);
      setIsLoggedIn(true);
    } else if (!storedToken) {
      setIsLoggedIn(false);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('stayLoggedIn');
    setIsLoggedIn(false);
    setToken(null);
  };

  return (
    <Router>
      <GlobalStyle />
      <AppContainer>
        <Navbar isLoggedIn={isLoggedIn} onLogout={handleLogout} />
        <Content>
          <Routes>
            <Route path="/auth/login" element={<PublicRoute element={Login} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/auth/register" element={<PublicRoute element={Register} isLoggedIn={isLoggedIn} />} />
            <Route path="/auth/forgot-password" element={<PublicRoute element={ForgotPassword} isLoggedIn={isLoggedIn} />} />
            <Route path="/auth/verifyemail" element={<PublicRoute element={VerifyEmail} isLoggedIn={isLoggedIn} />} />
            <Route
              path="/"
              element={
                isLoggedIn ? (
                  <Navigate to="/despesas" />
                ) : (
                  <Navigate to="/auth/login" />
                )
              }
            />
            <Route
              path="/assinaturas"
              element={<ProtectedRoute element={SubscriptionPage} isLoggedIn={isLoggedIn} />}
            />
            <Route
              path="/fazer-assinatura"
              element={<ProtectedRoute element={FormSubscriptionPage} isLoggedIn={isLoggedIn} />}
            />
            <Route
              path="/dados-pessoais"
              element={<ProtectedRoute element={DadosPessoais} isLoggedIn={isLoggedIn} />}
            />
            <Route
              path="/forma-pagamento"
              element={<ProtectedRoute element={FormaPagamento} isLoggedIn={isLoggedIn} />}
            />
            <Route
              path="/tipo-despesa"
              element={<ProtectedRoute element={TipoDespesa} isLoggedIn={isLoggedIn} />}
            />
            <Route
              path="/visao-geral"
              element={<ProtectedRoute element={VisaoGeral} isLoggedIn={isLoggedIn} />}
            />
            <Route
              path="/despesas"
              element={<ProtectedRoute element={Despesas} isLoggedIn={isLoggedIn} />}
            />
            <Route
              path="/receitas"
              element={<ProtectedRoute element={Receitas} isLoggedIn={isLoggedIn} />}
            />
            <Route path="/fale-conosco" element={<FaleConosco />} />
            <Route path="/saiba-mais" element={<LandingPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Content>
        <Footer email={email} />
      </AppContainer>
    </Router>
  );
};

export default App;

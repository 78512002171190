import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Logo from '../img/flying-money.png';
import { Link, useNavigate } from 'react-router-dom';
import { FaBars, FaTimes, FaSignOutAlt } from 'react-icons/fa';
import api from '../api';

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #042616;
  min-width: 360px; /* Set min-width for smaller screens */
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  width: 50px;
`;

const AppName = styled.span`
  color: #D9D9D9;
  font-size: 28px;
  margin-left: -25px;
  flex-grow: 1;
  text-align: center;
`;

const MenuButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #D9D9D9;
  font-size: 24px;
`;

const SidebarMenu = styled.div`
  position: fixed;
  top: 70px;
  right: 0;
  width: 300px;
  background-color: #D9D9D9;
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.5);
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  max-height: calc(100% - 70px);
  overflow-y: auto;
`;

const SidebarItem = styled(Link)`
  padding: 15px 20px;
  color: #042616;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #0DA65F;
  }
`;

const SidebarButton = styled.button`
  padding: 15px 20px;
  color: #042616;
  background-color: #D9D9D9;
  border: none;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #0DA65F;
  }
`;

const Overlay = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const AlertContainer = styled.div`
  background-color: #042616;
  color: #D9D9D9;
  border-radius: 8px;
  border: 4px solid #0DA65F; /* Adiciona a borda de 4px */
  padding: 20px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
`;

const AlertButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const AlertButton = styled.button`
  background-color: #0DA65F;
  color: #D9D9D9;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #11D97C;
  }
`;

const Navbar = ({ isLoggedIn, onLogout }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [confirmLogoutOpen, setConfirmLogoutOpen] = useState(false);
  const navigate = useNavigate();

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  const handleLogoutClick = () => {
    setMenuOpen(false); // Fechar o menu lateral
    setConfirmLogoutOpen(true);
  };

  const handleConfirmLogout = async () => {
    setConfirmLogoutOpen(false);
    const userId = localStorage.getItem('userId');
    try {
      await api.post('/auth/logout', { userId });
      localStorage.removeItem('token');
      localStorage.removeItem('email');
      localStorage.removeItem('userId');
      onLogout();
      navigate('/auth/login');
    } catch (error) {
      console.error('Erro ao fazer logout', error);
    }
  };

  const handleCancelLogout = () => {
    setConfirmLogoutOpen(false);
  };

  const handleOutsideClick = (event) => {
    if (confirmLogoutOpen && !event.target.closest('.confirmation-dialog')) {
      handleCancelLogout();
    }
  };

  useEffect(() => {
    if (confirmLogoutOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [confirmLogoutOpen]);

  return (
    <>
      <NavbarContainer>
        <LogoContainer>
          <LogoImage src={Logo} alt="Logo" />
        </LogoContainer>
        <AppName>Quanto Gastei?</AppName>
        {isLoggedIn && (
          <MenuButton className="menu-button" onClick={handleMenuToggle}>
            {menuOpen ? <FaTimes /> : <FaBars />}
          </MenuButton>
        )}
      </NavbarContainer>
      <Overlay isOpen={menuOpen} onClick={handleCloseMenu} />
      <SidebarMenu isOpen={menuOpen} className="sidebar-menu">
        {/*<SidebarItem to="/assinaturas" onClick={handleCloseMenu}>Assinaturas</SidebarItem>*/} 
        <SidebarItem to="/dados-pessoais" onClick={handleCloseMenu}>Dados pessoais</SidebarItem>
        <SidebarItem to="/despesas" onClick={handleCloseMenu}>Lançar despesas</SidebarItem>
        <SidebarItem to="/receitas" onClick={handleCloseMenu}>Lançar receitas</SidebarItem>
        <SidebarItem to="/visao-geral" onClick={handleCloseMenu}>Visão geral</SidebarItem>
        <SidebarItem to="/forma-pagamento" onClick={handleCloseMenu}>Forma de pagamento</SidebarItem>
        <SidebarItem to="/tipo-despesa" onClick={handleCloseMenu}>Tipo de despesa</SidebarItem>
        <SidebarButton onClick={handleLogoutClick}>
          <FaSignOutAlt style={{ marginRight: '10px' }} />
          Sair
        </SidebarButton>
      </SidebarMenu>
      {confirmLogoutOpen && (
        <AlertContainer className="confirmation-dialog">
          <p>Você realmente deseja sair da aplicação?</p>
          <AlertButtonContainer>
            <AlertButton onClick={handleConfirmLogout}>Sim</AlertButton>
            <AlertButton onClick={handleCancelLogout} cancel>Não</AlertButton>
          </AlertButtonContainer>
        </AlertContainer>
      )}
    </>
  );
};

export default Navbar;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import { FaTrash } from "react-icons/fa";
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import api from '../api';

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  max-width: 300px; /* Set max-width for larger screens */
  min-width: 300px; /* Set min-width for smaller screens */
`;

const Title = styled.h1`
  text-align: center;
  margin: 20px;
  color: #042616;
  font-size: 23.3px; /* Tamanho da fonte igual ao de Lancamentos */
`;

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza os componentes do formulário */
  background-color: #042616;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #075933;
  width: 100%;
  max-width: 300px; /* Set max-width for larger screens */
  min-width: 300px; /* Set min-width for smaller screens */
`;

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px; /* Diminuir o espaçamento */
  margin-bottom: 8px; /* Diminuir o espaçamento */
`;

const Label = styled.label`
  padding: 0px 10px;
  color: #D9D9D9; /* Text color */
  font-size: 14px; /* Diminuir o tamanho da fonte */
`;

const Input = styled.input`
  width: 280px; /* Increased width */
  padding: 0px 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 40px;
  margin: 10px;
  color: #042616; /*cor do texto do input */
  background-color: #D9D9D9; /* Input background color */
  font-size: 18px; /* Increased font size */
  &:focus {
    outline: 2px solid #0DA65F; /* Cor de foco */
    background-color: #D9D9D9; /* Cor de fundo ao focar */
  }
`;

const Button = styled.button`
  padding: 12px 24px; /* Increased padding */
  width: 240px;
  height: 52px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: #0DA65F; /* Button color */
  color: #D9D9D9; /* Text color */
  font-size: 24px; /* Increased font size */
  margin: 20px 40px;

  &:hover {
    background-color: #11D97C; /* Hover color */
  }
  &:disabled {
    background-color: #bbb;
    cursor: not-allowed;
  }
`;

const Message = styled.p`
  color: #D9D9D9;
  font-size: 10px; /* Aumentar a fonte em 2px */
  margin-top: 0px;
  align-self: flex-start; /* Posicionar no canto inferior esquerdo do container */
`;

const Table = styled.table`
  width: 100%;
  max-width: 340px; /* Set max-width for larger screens */
  min-width: 340px; /* Set min-width for smaller screens */
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 5px #ccc;
  border-radius: 5px;
  margin: 20px -10px;
  word-break: break-all;
  color: #042616; /* Cor do texto */
`;

const Thead = styled.thead``;

const Tbody = styled.tbody``;

const Tr = styled.tr`
  &:hover {
    background-color: #0DA65F; /* Cor de fundo ao passar o mouse */
  }
`;

const Th = styled.th`
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;
  color: #042616; /* Cor do texto */
`;

const Td = styled.td`
  padding-top: 10px;
  color: #042616; /* Cor do texto */
`;

const IconButton = styled(FaTrash)`
  color: #042616; /* Cor do ícone */
  cursor: pointer;
`;

const AlertContainer = styled.div`
  background-color: #042616;
  color: #D9D9D9;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center; /* Centralizar conteúdo */
`;

const AlertButtonContainer = styled.div`
  display: flex;
  justify-content: center; /* Centralizar botões */
  margin-top: 20px; /* Espaçamento entre texto e botões */
`;

const AlertButton = styled.button`
  background-color: #0DA65F;
  color: #D9D9D9;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #11D97C;
  }
`;

const StyledToastContainer = styled(ToastContainer)`
  top: 80px;
`;

const FormaPagamento = () => {
  const [formPayments, setFormPayments] = useState([]);
  const [nomeFormPayment, setNomeFormPayment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getFormPayments = async () => {
    try {
      const res = await api.get('/forma-pagamento');
      setFormPayments(res.data);
    } catch (error) {
      toast.error('Erro ao buscar formas de pagamento');
      console.error(error);
    }
  };

  const handleAddFormPayment = async (e) => {
    e.preventDefault();

    if (!nomeFormPayment.trim()) {
      toast.warn('Informe a nova forma de pagamento');
      return;
    }

    if (nomeFormPayment.length > 50) {
      toast.warn('Nova forma de pagamento deve ter no máximo 50 caracteres');
      return;
    }

    // Função para formatar o texto
    const formatText = (text) => {
      return text
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    };

    const formattedFormPayment = formatText(nomeFormPayment);

    setIsSubmitting(true);

    try {
      await api.post('/forma-pagamento', { nome_form_payment: formattedFormPayment });
      toast.success('Forma de pagamento adicionada com sucesso');
      setNomeFormPayment('');
      getFormPayments();
    } catch (error) {
      toast.error('Erro ao adicionar forma de pagamento');
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteFormPayment = async (id) => {
    try {
      await api.delete(`/forma-pagamento/${id}`);
      toast.success('Forma de pagamento deletada com sucesso');
      getFormPayments();
    } catch (error) {
      toast.error('Erro ao deletar forma de pagamento');
      console.error(error);
    }
  };

  const confirmDelete = (id, nome) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AlertContainer>
            <h1>Confirmação</h1>
            <p>Você realmente quer deletar a forma de pagamento "{nome}"?</p>
            <AlertButtonContainer>
              <AlertButton onClick={() => { handleDeleteFormPayment(id); onClose(); }}>Sim</AlertButton>
              <AlertButton onClick={onClose}>Não</AlertButton>
            </AlertButtonContainer>
          </AlertContainer>
        );
      }
    });
  };

  useEffect(() => {
    getFormPayments();
  }, []);

  const maxFormPayments = 15;
  const activeFormPayments = formPayments.filter(item => !item.deletado).length;
  const canAddMore = activeFormPayments < maxFormPayments;

  return (
    <Container>
      <Title>Forma de pagamento</Title>
      <FormStyled onSubmit={handleAddFormPayment}>
        <InputArea>
          <Label>Nova forma de pagamento:</Label>
          <Input
            type="text"
            value={nomeFormPayment}
            onChange={(e) => setNomeFormPayment(e.target.value)}
            disabled={!canAddMore || isSubmitting}
          />
        </InputArea>
        <Button type="submit" disabled={!canAddMore || isSubmitting}>Adicionar</Button>
        {!canAddMore && <Message>*Você pode ter no máximo 15 formas de pagamento.</Message>}
        {formPayments.length === 0 && <Message>*Você não tem nenhuma forma de pagamento cadastrada.</Message>}
      </FormStyled>
      {formPayments.length > 0 && (
        <Table>
          <Thead>
            <Tr>
              <Th>Suas formas de pagamento</Th>
              <Th> </Th>
            </Tr>
          </Thead>
          <Tbody>
            {formPayments.map((item, i) => (
              <Tr key={i}>
                <Td>{item.nome_form_payment}</Td>
                <Td>
                  <IconButton onClick={() => confirmDelete(item.id_form_payment, item.nome_form_payment)} />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
      <StyledToastContainer autoClose={3000} position="top-left" />
    </Container>
  );
};

export default FormaPagamento;

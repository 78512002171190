import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import api from '../api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  max-width: 300px; /* Set max-width for larger screens */
  min-width: 300px; /* Set min-width for smaller screens */
`;

const Title = styled.h1`
  text-align: center;
  margin: 20px;
  color: #042616;
  font-size: 24px; /* Tamanho da fonte igual ao de Lancamentos */
`;

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza os componentes do formulário */
  background-color: #042616;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #075933;
  width: 100%;
  max-width: 300px; /* Set max-width for larger screens */
  min-width: 300px; /* Set min-width for smaller screens */
`;

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px; /* Diminuir o espaçamento */
  margin-bottom: 8px; /* Diminuir o espaçamento */
`;

const Label = styled.label`
  padding: 0px 10px;
  color: #D9D9D9; /* Text color */
  font-size: 14px; /* Diminuir o tamanho da fonte */
`;

const Input = styled.input`
  width: 280px; /* Increased width */
  padding: 0px 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 40px;
  margin: 10px;
  color: ${({ disabled }) => (disabled ? '#A0A0A0' : '#042616')}; /* cor do texto do input */
  background-color: #D9D9D9; /* Input background color */
  font-size: 18px; /* Increased font size */
  &:focus {
    outline: 2px solid #0DA65F; /* Cor de foco */
    background-color: #D9D9D9; /* Cor de fundo ao focar */
  }
`;

const Button = styled.button`
  padding: 12px 24px; /* Increased padding */
  width: 240px;
  height: 52px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: #0DA65F; /* Button color */
  color: #D9D9D9; /* Text color */
  font-size: 24px; /* Increased font size */
  margin: 20px 40px;

  &:hover {
    background-color: #11D97C; /* Hover color */
  }

  &:disabled {
    background-color: #bbb;
    cursor: not-allowed;
  }
`;

const LinksContainer = styled.div`
  width: 100%;
  max-width: 330px; /* Ajustar para combinar com a largura do formulário */
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;
`;

const StyledLink = styled.a`
  color: ${({ disabled }) => (disabled ? '#bbb' : '#042616')};
  text-decoration: ${({ disabled }) => (disabled ? 'none' : 'none')};
  font-size: 14px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    &:hover {
    text-decoration: underline;
  }
`;

const StyledToastContainer = styled(ToastContainer)`
  top: 80px;
`;

const VerifyEmail = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const codeRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    if (location.state && location.state.email) {
      setEmail(location.state.email);
    }
  }, [location.state]);

  const handleCodeChange = (e) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 5);
    setCode(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      toast.warn('Informe um email válido');
      return;
    }

    if (!code) {
      toast.warn('Informe o código de verificação enviado para o email cadastrado');
      return;
    }

    setIsSubmitting(true);

    try {
      await api.post('/auth/verifyemail', { email, code });
      toast.success('Email verificado com sucesso. Você será direcionado para a tela de login');
      setTimeout(() => {
        navigate('/auth/login');
      }, 4000);
    } catch (error) {
      setIsSubmitting(false);
      const errorMessage = error.response?.data || 'Erro ao verificar email';
      toast.warn(errorMessage);
      console.error('Erro ao verificar email', error);
    }
  };

  const handleResendCode = async (e) => {
    e.preventDefault();
    try {
      await api.post('/auth/resendcode', { email });
      toast.success('Código de verificação reenviado com sucesso.');
      setIsResendDisabled(true);
      setTimeout(() => {
        setIsResendDisabled(false);
      }, 180000); // 3 minutos em milissegundos
    } catch (error) {
      const errorMessage = error.response?.data || 'Erro ao reenviar código';
      toast.error(errorMessage);
    }
  };

  return (
    <Container>
      <Title>Verificar Email</Title>
      <FormStyled onSubmit={handleSubmit}>
        <InputArea>
          <Label>Email</Label>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={isSubmitting}
          />
        </InputArea>
        <InputArea>
          <Label>Código de Verificação</Label>
          <Input
            type="text"
            value={code}
            onChange={handleCodeChange}
            ref={codeRef}
            disabled={isSubmitting}
          />
        </InputArea>
        <Button type="submit" disabled={isSubmitting}>Verificar</Button>
      </FormStyled>
      <LinksContainer>
        <StyledLink href="#" onClick={handleResendCode} disabled={isResendDisabled}>
          Reenviar código de verificação
        </StyledLink>
      </LinksContainer>
      <StyledToastContainer autoClose={3000} position="top-left" />
    </Container>
  );
};

export default VerifyEmail;

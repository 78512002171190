import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import api from '../api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Link } from 'react-router-dom';

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  max-width: 300px; /* Set max-width for larger screens */
  min-width: 300px; /* Set min-width for smaller screens */
`;

const Title = styled.h1`
  text-align: center;
  margin: 20px;
  color: #042616;
  font-size: 24px; /* Tamanho da fonte igual ao de Lancamentos */
`;

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza os componentes do formulário */
  background-color: #042616;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #075933;
  width: 100%;
  max-width: 300px; /* Set max-width for larger screens */
  min-width: 300px; /* Set min-width for smaller screens */
`;

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px; /* Diminuir o espaçamento */
  margin-bottom: 8px; /* Diminuir o espaçamento */
`;

const Label = styled.label`
  padding: 0px 10px;
  color: #D9D9D9; /* Text color */
  font-size: 14px; /* Diminuir o tamanho da fonte */
`;

const Input = styled.input`
  width: 280px; /* Increased width */
  padding: 0px 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 40px;
  margin: 10px;
  color: #042616; /*cor do texto do input */
  background-color: #D9D9D9; /* Input background color */
  font-size: 18px; /* Increased font size */
  &:focus {
    outline: 2px solid #0DA65F; /* Cor de foco */
    background-color: #D9D9D9; /* Cor de fundo ao focar */
  }
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0; /* Ajustar o espaço entre os elementos */
  color: #D9D9D9; /* Text color */
  width: 100%;
`;

const RadioLabel = styled.label`
  margin-left: 5px;
  color: #D9D9D9; /* Text color */
  font-size: 14px;
`;

const RadioInput = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #bbb;
  border-radius: 3px; /* Tornar o checkbox quadrado */
  background-color: #D9D9D9;
  cursor: pointer;

  &:checked {
    background-color: #0DA65F; /* Cor de fundo ao ser selecionado */
    border-color: #042616;
  }

  &:focus {
    outline: 2px solid #0DA65F; /* Cor de foco */
  }
`;

const Button = styled.button`
  padding: 12px 24px; /* Increased padding */
  width: 240px;
  height: 52px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: #0DA65F; /* Button color */
  color: #D9D9D9; /* Text color */
  font-size: 24px; /* Increased font size */
  margin: 20px 40px;

  &:hover {
    background-color: #11D97C; /* Hover color */
  }

  &:disabled {
    background-color: #bbb;
    cursor: not-allowed;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 330px; /* Ajustar para combinar com a largura do formulário */
  margin-top: 4px;
`;

const StyledLink = styled(Link)`
  color: #042616;
  text-decoration: none;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledToastContainer = styled(ToastContainer)`
  top: 80px;
`;

const Login = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [stayLoggedIn, setStayLoggedIn] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleStayLoggedInChange = () => {
    setStayLoggedIn(!stayLoggedIn);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !validateEmail(email)) {
      toast.warn('Informe um email válido');
      return;
    }

    if (!password) {
      toast.warn('Informe sua senha');
      return;
    }

    if (password.length < 8) {
      toast.warn('Informe sua senha com pelo menos 8 caracteres');
      return;
    }

    if (password.length > 40) {
      toast.warn('Informe sua senha com no máximo 40 caracteres');
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await api.post('/auth/login', { email, password, stayLoggedIn });
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('email', email);
      localStorage.setItem('userId', response.data.userId); // Armazene o userId no localStorage
      if (stayLoggedIn) {
        localStorage.setItem('stayLoggedIn', 'true');
      }
      setIsLoggedIn(true);
      toast.success('Login realizado com sucesso');
      navigate('/despesas');
    } catch (error) {
      console.error('Erro ao realizar login', error);
      const errorMessage = error.response?.data || 'Erro ao realizar login';
      toast.warn(errorMessage);
      emailRef.current.focus();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      <Title>Login</Title>
      <FormStyled onSubmit={handleSubmit}>
        <InputArea>
          <Label>Email</Label>
          <Input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            ref={emailRef}
            disabled={isSubmitting}
          />
        </InputArea>
        <InputArea>
          <Label>Senha</Label>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            ref={passwordRef}
            disabled={isSubmitting}
          />
        </InputArea>
        <RadioContainer>
          <RadioInput
            name="stayLoggedIn"
            checked={stayLoggedIn}
            onChange={handleStayLoggedInChange}
            disabled={isSubmitting}
          />
          <RadioLabel>Manter conectado?</RadioLabel>
        </RadioContainer>
        <Button type="submit" disabled={isSubmitting}>Entrar</Button>
      </FormStyled>
      <LinksContainer>
        <StyledLink to="/auth/forgot-password">Esqueci minha senha</StyledLink>
        <StyledLink to="/auth/register">Registrar</StyledLink>
      </LinksContainer>
      <StyledToastContainer autoClose={3000} position="top-left" />
    </Container>
  );
};

export default Login;

import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../api';  

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  max-width: 300px; /* Set max-width for larger screens */
  min-width: 300px; /* Set min-width for smaller screens */
`;

const Title = styled.h1`
  text-align: center;
  margin: 20px;
  color: #042616;
  font-size: 24px; /* Tamanho da fonte igual ao de Lancamentos */
`;

const InfoText = styled.p`
  text-align: center;
  color: #042616;
  font-size: 14px;
  margin-bottom: 10px;
`;

const InstagramLink = styled.a`
  color: #042616;
  text-decoration: underline;
`;

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza os componentes do formulário */
  background-color: #042616;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #075933;
  width: 100%;
  max-width: 300px; /* Set max-width for larger screens */
  min-width: 300px; /* Set min-width for smaller screens */
`;

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px; /* Diminuir o espaçamento */
  margin-bottom: 8px; /* Diminuir o espaçamento */
`;

const Label = styled.label`
  padding: 0px 10px;
  color: #D9D9D9; /* Text color */
  font-size: 14px; /* Diminuir o tamanho da fonte */
`;

const Input = styled.input`
  width: 280px; /* Increased width */
  padding: 0px 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 40px;
  margin: 10px;
  color: ${({ disabled }) => (disabled ? '#A0A0A0' : '#042616')}; /* cor do texto do input */
  background-color: #D9D9D9; /* Input background color */
  font-size: 18px; /* Increased font size */
  &:focus {
    outline: 2px solid #0DA65F; /* Cor de foco */
    background-color: #D9D9D9; /* Cor de fundo ao focar */
  }
`;

const TextArea = styled.textarea`
  width: 280px; /* Increased width */
  padding: 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 150px;
  margin: 10px;
  color: #042616; /* cor do texto do input */
  background-color: #D9D9D9; /* Input background color */
  font-size: 18px; /* Increased font size */
  resize: none; /* Prevent resizing */
  &:focus {
    outline: 2px solid #0DA65F; /* Cor de foco */
    background-color: #D9D9D9; /* Cor de fundo ao focar */
  }
`;

const Button = styled.button`
  padding: 12px 24px; /* Increased padding */
  width: 240px;
  height: 52px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: #0DA65F; /* Button color */
  color: #D9D9D9; /* Text color */
  font-size: 24px; /* Increased font size */
  margin: 20px 40px;

  &:hover {
    background-color: #11D97C; /* Hover color */
  }

  &:disabled {
    background-color: #bbb;
    cursor: not-allowed;
  }
`;

const StyledToastContainer = styled(ToastContainer)`
  top: 80px;
`;

const FaleConosco = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const emailRef = useRef(null);
  const messageRef = useRef(null);

  const userEmail = localStorage.getItem('email');

  useEffect(() => {
    if (userEmail) {
      setEmail(userEmail);
    }
  }, [userEmail]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      toast.warn('Informe um email válido');
      return;
    }

    if (!message) {
      toast.warn('Informe a mensagem');
      return;
    }

    if (message.length < 5) {
      toast.warn('Informe a mensagem com no mínimo 10 caracteres');
      return;
    }

    if (message.length > 1500) {
      toast.warn('Informe a mensagem com no máximo 1.500 caracteres');
      return;
    }

    setIsSubmitting(true);

    try {
      await api.post('/contact', {
        email,
        message,
      });
      toast.success('Mensagem enviada com sucesso');
      if (!userEmail) {
        setEmail('');
      }
      setMessage('');
    } catch (error) {
      const errorMessage = error.response?.data || 'Erro ao enviar mensagem';
      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      <Title>Fale conosco</Title>
      <InfoText>Digite abaixo como podemos te ajudar? Se preferir, fale conosco através do{' '}
        <InstagramLink href="https://www.instagram.com/quantogastei.app" target="_blank">Instagram</InstagramLink></InfoText>
      <FormStyled onSubmit={handleSubmit}>
        <InputArea>
          <Label>Email</Label>
          <Input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            ref={emailRef}
            disabled={!!userEmail}
          />
        </InputArea>
        <InputArea>
          <Label>Mensagem</Label>
          <TextArea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            ref={messageRef}
          />
        </InputArea>
        <Button type="submit" disabled={isSubmitting}>Enviar</Button>
      </FormStyled>
      <StyledToastContainer autoClose={3000} position="top-left" />
    </Container>
  );
};

export default FaleConosco;

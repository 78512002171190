import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  text-align: center;
`;

const Title = styled.h1`
  color: #042616;
  font-size: 24px;
  margin: 20px;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  margin-top: 20px;
`;

const NotFound = () => {
  return (
    <Container>
      <Title>Página não encontrada</Title>
    </Container>
  );
};

export default NotFound;

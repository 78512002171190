import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { NumericFormat } from 'react-number-format';
import api from '../api';

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza os componentes do formulário */
  background-color: #042616;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #075933;
  width: 100%;
  max-width: 300px; /* Set max-width for larger screens */
  min-width: 300px; /* Set min-width for smaller screens */
`;

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px; /* Diminuir o espaçamento */
  margin-bottom: 8px; /* Diminuir o espaçamento */
`;

const Label = styled.label`
  padding: 0px 10px;
  color: #D9D9D9; /* Text color */
  font-size: 14px; /* Diminuir o tamanho da fonte */
`;

const Button = styled.button`
  padding: 12px 24px; /* Increased padding */
  width: 240px;
  height: 52px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: #0DA65F; /* Button color */
  color: #D9D9D9; /* Text color */
  font-size: 24px; /* Increased font size */
  margin: 20px 40px;

  &:hover {
    background-color: #11D97C; /* Hover color */
  }

  &:disabled {
    background-color: #bbb;
    cursor: not-allowed;
  }
`;

const NumberInput = styled(NumericFormat)`
  width: 280px; /* Increased width */
  padding: 0px 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 40px;
  margin: 10px;
  color: #042616; /*cor do texto do input */
  background-color: #D9D9D9; /* Input background color */
  font-size: 18px; /* Increased font size */
  &:focus {
    outline: 2px solid #0DA65F; /* Cor de foco */
    background-color: #D9D9D9; /* Cor de fundo ao focar */
  }
`;

const TextInput = styled.input`
  width: 280px; /* Increased width */
  padding: 0px 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 40px;
  margin: 10px;
  color: #042616; /*cor do texto do input */
  background-color: #D9D9D9; /* Input background color */
  font-size: 18px; /* Increased font size */
  &:focus {
    outline: 2px solid #0DA65F; /* Cor de foco */
    background-color: #D9D9D9; /* Cor de fundo ao focar */
  }
`;

const DateInput = styled.input`
  width: 280px;
  padding: 0px 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 40px;
  margin: 10px;
  color: #042616;
  background-color: #D9D9D9;
  font-size: 18px;
  font-family: sans-serif;
  font-weight: normal; /* Fonte normal */
  &:focus {
    outline: 2px solid #0DA65F;
    background-color: #D9D9D9;
  }
`;

const StyledToastContainer = styled(ToastContainer)`
  top: 80px;
`;

const FormReceitas = ({ onEdit, setOnEdit }) => {
  const navigate = useNavigate();
  const descricaoRef = useRef(null);
  const [valor, setValor] = useState('0,00');


  // Função para obter a data local do usuário
  const getLocalDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [dataReceita, setDataReceita] = useState(getLocalDate());
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (onEdit) {
      setValor(onEdit.valor_revenue);
      setDataReceita(onEdit.date_revenue || getLocalDate());
      if (descricaoRef.current) descricaoRef.current.value = onEdit.descricao_revenue;
    } else {
      setValor('0,00');
    }
  }, [onEdit]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmitting(true);

    try {
      // Verificar a data de expiração da assinatura
      const expirationResponse = await api.get(`/subscriptions/expiration-date?userId=${localStorage.getItem('userId')}`);
      const expirationDate = new Date(expirationResponse.data.expiration_date_subscriptions);
      const today = new Date();

      // Formatando as datas no padrão UTC
      const expirationDateUTC = new Date(Date.UTC(expirationDate.getUTCFullYear(), expirationDate.getUTCMonth(), expirationDate.getUTCDate()));
      const todayUTC = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));

      // Zerando as horas, minutos, segundos e milissegundos
      expirationDateUTC.setHours(0, 0, 0, 0);
      todayUTC.setHours(0, 0, 0, 0);

      if (expirationDateUTC < todayUTC) {
        toast.warn('Renove sua assinatura para lançar novas receitas', expirationDate);
        setIsSubmitting(false);
        return;
      }
    } catch (error) {
      console.error('Erro ao verificar a data de expiração da assinatura:', error);
      toast.error('Erro ao verificar a assinatura.');
      setIsSubmitting(false);
      return;
    }

    // Validações dos campos
    if (!dataReceita) {
      toast.warn('Informe uma data válida para a receita');
      setIsSubmitting(false);
      return;
    }

    const valorNumerico = parseFloat(valor.replace(/\./g, '').replace(',', '.'));
    if (isNaN(valorNumerico) || valorNumerico <= 0) {
      toast.warn('Informe um valor numérico maior que zero');
      setIsSubmitting(false);
      return;
    }

    if (valorNumerico >= 1000000000) {
      toast.warn('Informe um valor menor que 1.000.000.000,00');
      setIsSubmitting(false);
      return;
    }

    if (!descricaoRef.current?.value) {
      toast.warn('Informe uma descrição');
      setIsSubmitting(false);
      return;
    }

    if (descricaoRef.current?.value.length > 80) {
      toast.warn('Descrição da receita deve ter no máximo 80 caracteres');
      setIsSubmitting(false);
      return;
    }

    // Função para formatar o texto
    const formatText = (text) => {
      return text
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    };

    const formattedDescricao = formatText(descricaoRef.current.value);

    // Manter a data selecionada com hora fixa ao meio-dia
    const [year, month, day] = dataReceita.split('-');
    const dataLocal = new Date(Date.UTC(year, month - 1, day, 12, 0, 0));

    // Transformar a data em timestamp
    const dataFormatada = dataLocal.toISOString().replace('T', ' ').split('.')[0];

    let mesCorrente = String(dataLocal.getUTCMonth() + 1).padStart(2, '0'); 
    let anoCorrente = dataLocal.getUTCFullYear(); 

    try {
      const revenue = {
        valor_revenue: parseFloat(valorNumerico),
        descricao_revenue: formattedDescricao,
        date_revenue: dataFormatada,
        ano_revenue: anoCorrente,
        mes_revenue: mesCorrente,
      };

      await api.post('/revenue', revenue);
      toast.success('Receita adicionada com sucesso');
      // Limpar os campos após o salvamento
      setOnEdit(null);
      setValor('0,00');
      setDataReceita(getLocalDate());
      if (descricaoRef.current) descricaoRef.current.value = '';
    } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data === 'Limite atingido') {
        toast.warn('Limite atingido - Você pode fazer no máximo 300 lançamentos por dia');
      } else {
        console.error('Error saving revenue:', error);
        toast.error('Erro ao salvar a receita');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <InputArea>
        <Label>Data da receita:</Label>
        <DateInput
          type="date"
          value={dataReceita}
          onChange={(e) => setDataReceita(e.target.value)}
        />
      </InputArea>
      <InputArea>
        <Label>Valor total R$:</Label>
        <NumberInput
          name="valor_revenue"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          fixedDecimalScale={true}
          allowNegative={false}
          prefix=""
          value={valor}
          onValueChange={(values) => setValor(values.formattedValue)}
          onClick={(e) => e.target.select()} // Seleciona o conteúdo ao clicar
        />
      </InputArea>
      <InputArea>
        <Label>Descrição da receita:</Label>
        <TextInput name="descricao_revenue" type="text" ref={descricaoRef} />
      </InputArea>
      <Button type="submit" disabled={isSubmitting}>Salvar</Button>
      <StyledToastContainer autoClose={3000} position="top-left" />
    </FormContainer>
  );
};

export default FormReceitas;

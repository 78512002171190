import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import api from '../api';

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  max-width: 300px; /* Set max-width for larger screens */
  min-width: 300px; /* Set min-width for smaller screens */
`;

const Title = styled.h1`
  text-align: center;
  margin: 20px;
  color: #042616;
  font-size: 24px; /* Tamanho da fonte igual ao de Lancamentos */
`;

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza os componentes do formulário */
  background-color: #042616;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #075933;
  width: 100%;
  max-width: 300px; /* Set max-width for larger screens */
  min-width: 300px; /* Set min-width for smaller screens */
`;

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px; /* Diminuir o espaçamento */
  margin-bottom: 8px; /* Diminuir o espaçamento */
`;

const Label = styled.label`
  padding: 0px 10px;
  color: #D9D9D9; /* Text color */
  font-size: 14px; /* Diminuir o tamanho da fonte */
`;

const Input = styled.input`
  width: 280px; /* Increased width */
  padding: 0px 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 40px;
  margin: 10px;
  color: ${({ disabled }) => (disabled ? '#A0A0A0' : '#042616')}; /* cor do texto do input */
  background-color: #D9D9D9; /* Input background color */
  font-size: 18px; /* Increased font size */
  &:focus {
    outline: 2px solid #0DA65F; /* Cor de foco */
    background-color: #D9D9D9; /* Cor de fundo ao focar */
  }
`;

const Button = styled.button`
  padding: 12px 24px; /* Increased padding */
  width: 240px;
  height: 52px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: #0DA65F; /* Button color */
  color: #D9D9D9; /* Text color */
  font-size: 24px; /* Increased font size */
  margin: 20px 40px;

  &:hover {
    background-color: #11D97C; /* Hover color */
  }

  &:disabled {
    background-color: #bbb;
    cursor: not-allowed;
  }
`;

const StyledToastContainer = styled(ToastContainer)`
  top: 80px;
`;

const DadosPessoais = () => {
  const [email, setEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [phone, setPhone] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentPasswordRef = useRef(null);
  const newPasswordRef = useRef(null);
  const confirmNewPasswordRef = useRef(null);
  const fullNameRef = useRef(null);
  const phoneRef = useRef(null);
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await api.get(`/user?id=${userId}`);
        const { email_user, nome_user, fone_user } = response.data;
        setEmail(email_user);
        setFullName(nome_user);
        setPhone(fone_user);
      } catch (error) {
        console.error('Erro ao buscar dados do usuário', error);
        toast.error('Erro ao buscar dados do usuário');
      }
    };

    fetchUserData();
  }, [userId]);

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');
    if (value.length > 11) value = value.slice(0, 11);
    value = value.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1)$2-$3');
    setPhone(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (currentPassword.length < 8) {
      toast.warn('Dados não modificados. Senha inválida');
      return;
    }

    if (currentPassword.length > 40) {
      toast.warn('Dados não modificados. Senha inválida');
      return;
    }

    if (newPassword !== confirmNewPassword) {
      toast.warn('As novas senhas informadas estão diferentes');
      return;
    }

    if (fullName.length < 5) {
      toast.warn('Informe seu nome completo');
      return;
    }

    if (fullName.length > 100) {
      toast.warn('Informe seu nome completo com no máximo 100 caracteres');
      return;
    }

    // Função para formatar o texto
    const formatText = (text) => {
      return text
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    };

    const formattedFullName = formatText(fullName);

    if (phone.length < 11) {
      toast.warn('Informe seu número de celular');
      return;
    }

    setIsSubmitting(true);

    try {
      await api.put('/user', {
        id: userId,
        currentPassword,
        newPassword,
        nome_user: formattedFullName,
        fone_user: phone,
      });
      toast.success('Dados atualizados com sucesso');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
    } catch (error) {
      const errorMessage = error.response?.data || 'Erro ao atualizar dados do usuário';
      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      <Title>Dados Pessoais</Title>
      <FormStyled onSubmit={handleSubmit}>
        <InputArea>
          <Label>Email</Label>
          <Input
            type="text"
            value={email}
            disabled
          />
        </InputArea>
        <InputArea>
          <Label>Senha Atual</Label>
          <Input
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            ref={currentPasswordRef}
          />
        </InputArea>
        <InputArea>
          <Label>Nova Senha</Label>
          <Input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            ref={newPasswordRef}
          />
        </InputArea>
        <InputArea>
          <Label>Confirmação de Nova Senha</Label>
          <Input
            type="password"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            ref={confirmNewPasswordRef}
          />
        </InputArea>
        <InputArea>
          <Label>Nome Completo</Label>
          <Input
            type="text"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            ref={fullNameRef}
          />
        </InputArea>
        <InputArea>
          <Label>Celular</Label>
          <Input
            type="text"
            value={phone}
            onChange={handlePhoneChange}
            ref={phoneRef}
            placeholder="(99)99999-9999"
          />
        </InputArea>
        <Button type="submit" disabled={isSubmitting}>Atualizar</Button>
      </FormStyled>
      <StyledToastContainer autoClose={3000} position="top-left" />
    </Container>
  );
};

export default DadosPessoais;

import axios from 'axios';
import { toast } from 'react-toastify';

const api = axios.create({
  baseURL: 'https://api.quantogastei.net',
});

// Interceptor para adicionar o token em todas as requisições
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Interceptor para verificar o token expirado
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('email');
        localStorage.removeItem('userId');
        console.log('Sessão expirada. Por favor, faça login novamente.');
        toast.warn('Sessão expirada. Por favor, faça login novamente.');
        setTimeout(() => {
          window.location.href = '/auth/login';
        }, 2000); 
      } else if (error.response.status === 403) {
        localStorage.removeItem('token');
        localStorage.removeItem('email');
        localStorage.removeItem('userId');
        console.log('Acesso negado. Por favor, faça login novamente.');
        toast.warn('Acesso negado. Por favor, faça login novamente.');
        setTimeout(() => {
          window.location.href = '/auth/login';
        }, 2000); 
      }
    }
    return Promise.reject(error);
  }
);

export default api;
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import api from '../api';  

const TableWrapper = styled.div`
  width: 100%;
  max-width: 1000px; /* Set max-width for larger screens */
  margin: 0 auto; /* Centralizar horizontalmente */
  overflow-x: auto; /* Adicionar rolagem horizontal se necessário */
`;

const ScrollableTableContainer = styled.div`
  max-height: 500px; /* Limitar a altura do contêiner */
  overflow-y: auto; /* Adicionar rolagem vertical */
`;

const Table = styled.table`
  width: 100%;
  max-width: 1000px; /* Set max-width for larger screens */
  background-color: #fff;
  padding: 10px;
  box-shadow: 0px 0px 5px #ccc;
  margin: 10px 10px 0px 0px;
  border-radius: 5px;
  word-break: break-word;
  color: #042616; /* Cor do texto */
  table-layout: auto; /* As colunas terão largura flexível */
  font-size: 14px; /* Diminui o tamanho da fonte da tabela */
`;

const Thead = styled.thead``;

const Tbody = styled.tbody``;

const Tr = styled.tr`
  &:hover {
    background-color: #0DA65F; /* Cor de fundo ao passar o mouse */
  }
`;

const Th = styled.th`
  text-align: start;
  border-bottom: inset;
  padding: 5px;
  color: #042616; /* Cor do texto */
  white-space: nowrap; /* Impede que o texto quebre em várias linhas */
  font-weight: bold; /* Mantém o cabeçalho em negrito */
  font-size: 18px; /* Diminui o tamanho da fonte do cabeçalho */
`;

const Td = styled.td`
  padding: 5px;
  color: #042616; /* Cor do texto */
  white-space: nowrap; /* Impede que o texto quebre em várias linhas */
  font-size: 16px; /* Diminui o tamanho da fonte das linhas de registro */
`;

const GridAssinaturas = () => {
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      const userId = localStorage.getItem('userId'); // Obtenha o userId do localStorage

      try {
        const response = await api.get(`/subscriptions?userId=${userId}`);
        setSubscriptions(response.data);
      } catch (error) {
        toast.error('Erro ao buscar assinaturas');
      }
    };

    fetchSubscriptions();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Data Inválida';
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatCurrency = (value) => {
    return parseFloat(value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });
  };

  return (
    <TableWrapper>
      <ScrollableTableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Plano</Th>
              <Th>Método de Pagamento</Th>
              <Th>Data de Início</Th>
              <Th>Data de Expiração</Th>
              <Th>Valor R$</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {subscriptions.map((item, index) => (
              <Tr key={index}>
                <Td>{index + 1}</Td>
                <Td>{item.plan_subscriptions}</Td>
                <Td>{item.payment_method_subscriptions}</Td>
                <Td>{formatDate(item.start_date_subscriptions)}</Td>
                <Td>{formatDate(item.expiration_date_subscriptions)}</Td>
                <Td>{formatCurrency(item.amount_subscriptions)}</Td>
                <Td>{item.status_subscriptions}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </ScrollableTableContainer>
    </TableWrapper>
  );
};

export default GridAssinaturas;

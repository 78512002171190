import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Evita o scroll horizontal */
  }

  body {
    box-sizing: border-box;
    background-color: #D9D9D9; /* Background color */
    color: #D9D9D9; /* Text color */
    font-family: 'poppins', sans-serif;
    font-size: 18px; /* Increased font size */
    font-weight: 500; /* Increased font weight */
  }
`;

export default GlobalStyle;

import React from 'react';
import styled from 'styled-components';
import { FaTrash } from "react-icons/fa";
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import api from '../api';

const TableWrapper = styled.div`
  width: calc(100% - 20px); /* Para garantir que a tabela fique alinhada à esquerda com uma margem de 20px */
  overflow-x: auto; /* Adicionar rolagem horizontal se necessário */
`;

const ScrollableTableContainer = styled.div`
  max-height: 500px; /* Limitar a altura do contêiner */
  overflow-y: auto; /* Adicionar rolagem vertical */
`;

const Table = styled.table`
  width: 100%;
  max-width: 1000px; /* Set max-width for larger screens */
  background-color: #fff;
  padding: 10px;
  box-shadow: 0px 0px 5px #ccc;
  margin: 10px 10px 0px 0px;
  border-radius: 5px;
  word-break: break-word;
  color: #042616; /* Cor do texto */
  table-layout: auto; /* As colunas terão largura flexível */
  font-size: 14px; /* Diminui o tamanho da fonte da tabela */
`;

const Thead = styled.thead``;

const Tbody = styled.tbody``;

const Tr = styled.tr`
  &:hover {
    background-color: #0DA65F; /* Cor de fundo ao passar o mouse */
  }
`;

const Th = styled.th`
  text-align: start;
  border-bottom: inset;
  padding: 5px;
  color: #042616; /* Cor do texto */
  white-space: nowrap; /* Impede que o texto quebre em várias linhas */
  font-weight: bold; /* Mantém o cabeçalho em negrito */
  font-size: 18px; /* Diminui o tamanho da fonte do cabeçalho */
`;

const Td = styled.td`
  padding: 5px;
  color: #042616; /* Cor do texto */
  white-space: nowrap; /* Impede que o texto quebre em várias linhas */
  font-size: 16px; /* Diminui o tamanho da fonte das linhas de registro */
`;

const IconButton = styled(FaTrash)`
  color: #042616; /* Cor do ícone */
  cursor: pointer;
`;

const AlertContainer = styled.div`
  background-color: #042616;
  color: #D9D9D9;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 1000px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center; /* Centralizar conteúdo */
`;

const AlertButtonContainer = styled.div`
  display: flex;
  justify-content: center; /* Centralizar botões */
  margin-top: 20px; /* Espaçamento entre texto e botões */
`;

const AlertButton = styled.button`
  background-color: #0DA65F;
  color: #D9D9D9;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #11D97C;
  }
`;

const GridDespesas = ({ expenses = [], setExpenses }) => {
  const handleDelete = async (id) => {
    try {
      await api.put(`/expense/${id}`, { deletado_expense: 1 });
      const newArray = expenses.filter((expense) => expense.id_expense !== id);
      setExpenses(newArray);
      toast.success('Despesa excluída com sucesso');
    } catch (error) {
      toast.error('Erro ao excluir despesa');
    }
  };

  const confirmDelete = (id, descricao) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AlertContainer>
            <h1>Confirmação</h1>
            <p>Você realmente quer deletar o lançamento "{descricao}"?</p>
            <AlertButtonContainer>
              <AlertButton onClick={() => { handleDelete(id); onClose(); }}>Sim</AlertButton>
              <AlertButton onClick={onClose}>Não</AlertButton>
            </AlertButtonContainer>
          </AlertContainer>
        );
      }
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Data Inválida';
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatCurrency = (value) => {
    return parseFloat(value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });
  };

  return (
    <TableWrapper>
      <ScrollableTableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Data despesa</Th>
              <Th>Valor R$</Th>
              <Th>Forma de pagamento</Th>
              <Th>Parcelado</Th>
              <Th>Tipo de despesa</Th>
              <Th>Descrição</Th>
              <Th> </Th>
            </Tr>
          </Thead>
          <Tbody>
            {expenses.map((item, index) => (
              <Tr key={index}>
                <Td>{index + 1}</Td>
                <Td>{formatDate(item.date_expense)}</Td>
                <Td>{formatCurrency(item.valor_expense)}</Td>
                <Td>{item.formapgto_expense}</Td>
                <Td>{item.parcelado_expense ? 'Sim' : 'Não'}</Td>
                <Td>{item.tipo_expense}</Td>
                <Td>{item.descricao_expense}</Td>
                <Td>
                  <IconButton onClick={() => confirmDelete(item.id_expense, item.descricao_expense)} />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </ScrollableTableContainer>
    </TableWrapper>
  );
};

export default GridDespesas;

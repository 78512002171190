import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import VG1 from '../img/visao-geral1.jpg';
import VG2 from '../img/visao-geral2.jpg';
import VG3 from '../img/visao-geral3.jpg';
import D1 from '../img/despesas1.jpg';
import R1 from '../img/receitas1.jpg';
import FPTD1 from '../img/fp-td1.jpg';


const LandingContainer = styled.div`
  //width: 100%;
  margin: 0 auto;
  margin-top: 60px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFF; /* Background color */
  min-width: 300px; /* Set min-width for smaller screens */
  max-width: 1200px; /* Set max-width for larger screens */
`;

const Section = styled.div`
  margin: 20px 0;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 36px;
  color: #042616;
  margin-bottom: 20px;
  text-align: center;
`;

const Subtitle = styled.h2`
  font-size: 28px;
  color: #042616;
  margin-bottom: 20px;
  text-align: center;
`;

const Description = styled.p`
  font-size: 18px;
  color: #042616;
  margin-bottom: 20px;
  text-align: left;
  margin-left: 20px;
`;

const Description1 = styled.p`
  font-size: 16px;
  color: #042616;
  margin-bottom: 0px;
  text-align: left;
  margin-left: 20px;
`;

const Description2 = styled.p`
  font-size: 14px;
  color: #042616;
  margin-bottom: 5px;
  margin-top: 40px;
  text-align: left;
  margin-left: 4px;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  margin-top: 0px;
  margin-bottom: 40px;
`;

const RegisterButton = styled.button`
  padding: 15px 30px;
  font-size: 20px;
  color: #FFF;
  background-color: #0DA65F;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #11D97C;
  }
`;

const LandingPage = () => {
  const navigate = useNavigate();

  const handleRegister = () => {
    navigate('/auth/register');
  };

  return (
    <LandingContainer>
      <Title>Controle todas suas despesas agora</Title>

      <Section>
        <Subtitle>Veja seus gastos em tempo real</Subtitle>
        <Description>
          Quanto gastei esse mês? No mês passado? No ano passado?
        </Description>
        <Description>
          Tenha essas respostas a qualquer momento de forma simples e rápida. 
        </Description>
        <Description>
          Saiba exatamente COMO e ONDE você está gastando e tenha o controle total das suas finanças.
        </Description>
        <Description2>
          *Na tela visão geral você tem todos os seus gastos de forma fácil e clara:
        </Description2>
        <Image src={VG1} alt="Visão geral" />
        <Description2>
          *Também consegue ver todos os seus lançamentos (despesas e receitas) e se quiser pode salvá-los numa planilha:
        </Description2>
        <Image src={VG2} alt="Visão geral de despesas" />
        <Image src={VG3} alt="Visão geral de receitas" />
      </Section>

      <Section>
        <Subtitle>Lance suas despesas e receitas em questão de segundos</Subtitle>
        <Description>
          Lance suas despesas e receitas de maneira rápida e prática! Independente de onde estiver, seja com o celular, tablet ou notebook.
        </Description>
        <Description>
          Fez um pagamento ou recebeu uma mensagem do seu banco sinalizando um pagamento?
        </Description>
        <Description>
          Abra a aplicação e faça o lançamento em questão de segundos. Seja no mercado, na farmácia, no posto de combustível ou em qualquer lugar.
        </Description>
        <Description2>
          *Na tela lançar despesas é onde você lança suas despesas em questão de segundos:
        </Description2>
        <Image src={D1} alt="Lançar despesas" />
        <Description2>
          *Na tela lançar receitas é onde você lança suas receitas em questão de segundos:
        </Description2>
        <Image src={R1} alt="Lançar receitas" />
      </Section>

      <Section>
        <Subtitle>Cadastre suas formas de pagamento e tipos de despesa</Subtitle>
        <Description>
          Faça o cadastro da sua maneira. Aqui quem manda é você. 
        </Description>
        <Description>
          Exemplos de formas de pagamento:
          <Description1>
           - Boleto
          </Description1>
          <Description1>
            - Dinheiro
          </Description1>
         <Description1>
            - Cartão de crédito
          </Description1>
         <Description1>
           - Cartão de débito 
         </Description1>
          <Description1>
            - Pix
          </Description1>
          <Description1>
            - (Até 15 diferentes)
          </Description1>
        </Description>

        <Description>
          Exemplos de tipos de despesa:
          <Description1>
           - Alimentação
          </Description1>
          <Description1>
           - Delivery
          </Description1>
          <Description1>
            - Farmácia
          </Description1>
          <Description1>
            - Moradia
          </Description1>
          <Description1>
            - Transporte
          </Description1>
          <Description1>
           - (Até 20 diferentes)
          </Description1>
        </Description>

        <Description2>
          *Nas telas formas de pagamento e tipos de despesas é onde você cadastra da sua maneira:
        </Description2>
        <Image src={FPTD1} alt="Formas de pagamento e tipos de despesa" />
      </Section>

      <Section>
        <Subtitle>Gostou? Então não perca mais tempo</Subtitle>
        <Subtitle>Faça seu cadastro gratuitamente</Subtitle>
        <Description>
          Clique no botão abaixo e organize todos os seus gastos agora mesmo.
        </Description>
        <RegisterButton onClick={handleRegister}>CADASTRE-SE AGORA</RegisterButton>
      </Section>

    </LandingContainer>
  );
};

export default LandingPage;